.processing-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.2);
    z-index: 2;
    display: none;
    //Modifiers
    &.is-active {
        display: block;
    }
}