.about-us {
    .container-fixed {
        width: 90%;
        max-width: inherit;
    }
    .container-fixed.is-small {
        max-width: 80%;
    }
}

nav.navbar.is-about, div.navbar.is-about {
    min-height: 100vh !important;
    div#navbar {
        ul.nav.navbar-nav {
            li {
                &.has-submenu {

                    ul.user-menu {

                        li {
                            //Modifiers
                            &#nav-username {
                                color: $about-us-header-title;
                            }
                        }

                        li.cart-button a i {
                            color: $about-us-header-title;
                        }


                    }
                }
            }
        }
    }
}

header.about-us {
    padding: 0 0 40px;
    .container-fixed {
        .flex-container-row {
            div {
                h1 {
                    color: $about-us-header-title;
                    margin: 0 0 2.09vw;
                    font-size: 4.23vw;
                }
                h4 {
                    color: darken($about-us-header-text, 10%);
                    margin: 0 0 3.72vh;
                    font-size: 1.45vw;
                }
                p  {
                    color: $about-us-header-text;
                    margin: 0;
                    font-size: 1.10vw;
                    line-height: 30px;
                }
                img {
                    display: block;
                    width: 100%;
                    max-width: 40.3vw;
                    margin: 0 auto;
                }
                //Modifiers
                &:first-child {
                    align-self: flex-start;
                    padding: 14vh 0 0 7.55vh;
                }
                &:last-child {
                    padding-top: 40px;
                    img {
                        margin: 0;
                        margin-left: auto;
                        position: relative;
                        right: -25px;
                    }
                }
            }
        }
    }
}

div.information-two-col {
    padding: 25px 0 25px;
    margin-bottom: 50px;
    .container-fixed {
        .flex-container-row {
            div {
                text-align: left;
                span.label {
                    display: block;
                    text-align: left;
                    color: $about-us-information-label;
                    padding: 0;
                    font-size: 1.02vw !important;
                    font-weight: 100;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    margin: 0 0 25px;
                    line-height: 1.62;
                    //Modifiers
                    &.is-small {
                        
                    }
                }
                h2 {
                    color: $about-us-header-title;
                    margin: 0 0 25px;
                    font-size: 2.5vw;
                    letter-spacing: 0.5px;
                }
                h4 {
                    font-size: 1.25vw;
                    font-weight: bold;
                    margin-bottom: 0;
                    margin-top: 0;
                    letter-spacing: 4.3px;
                    text-transform: uppercase;
                    color: $textSubMenu;
                    line-height: 1.62;
                }
                p {
                    color: $textSubMenu;
                    font-size: 1vw;
                    line-height: 1.62;
                    margin: 0 0 1.63vw;
                    //Modifiers
                    &.address {
                        color: $b1;
                        font-size: 1.75vw;
                    }
                }
                a {
                    color: $about-us-information-label;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 0.88vw !important;
                    font-weight: 700;
                    letter-spacing: 2px;
                    span {
                        display: inline-block;
                        margin-left: 5px;
                    }
                    //States
                    &:hover {
                        cursor: pointer;
                    }
                }
                img {
                    display: block;
                    max-width: 34.50vw;
                    //Modifiers
                    &.to-left {
                        position: relative;
                        left: -25px;
                    }
                }
                .image-container {
                    position: relative;
                    max-width: 550px;
                    font-size: 16px;
                    margin-left: 2em;
                    >img {
                        max-width: unset;
                        width: 100%;
                        max-width: 26.55vw;
                    }
                    .rim-container {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        &.austin {
                            top: 87%;
                            left: 27%;
                        }
                        &.toronto {
                            top: 60%;
                            left: 50%;
                        }
                        &.nyc {
                            top: 67%;
                            left: 60%;
                        }
                        &.calgary {
                            top: 48%;
                            left: 16%;
                        }
                        &.los-angeles {
                            top: 76%;
                            left: 4%;
                        }
                        &.denver {
                            top: 72%;
                            left: 22%;
                        }
                        &.tampa {
                            top: 92%;
                            left: 50%;
                        }
                        .pin-container {
                            .pin-image-container {
                                position: relative;
                                z-index: 1;
                                >img {
                                    border-radius: 130px;
                                    width: 10px;
                                    height: 10px;
                                    margin-bottom: 2px;
                                }
                            }
                            .location-name-container {
                                position: absolute;
                                right: -16px;
                                top: 30px;
                                >.location-name {
                                    text-align: center;
                                    font-size: 14px;
                                }
                            }
                        }
                        .rim1,
                        .rim2,
                        .rim3 {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            border-radius: 50%;
                            width: 0em;
                            height: 0em;
                            background: transparent;
                        }
                        .rim1 {
                            //-webkit-animation: expand 2.5s linear infinite;
                            -webkit-animation: expand 3.5s linear infinite;
                        }
                        .rim2 {
                            //-webkit-animation: expand 2.5s linear .5s infinite;
                            -webkit-animation: expand 3.5s linear .5s infinite;
                        }
                        .rim3 {
                            //-webkit-animation: expand 2.5s linear 1s infinite;
                            -webkit-animation: expand 3.5s linear 1s infinite;
                        }
                        @-webkit-keyframes expand {
                            50% {
                                top: calc(50% - .5em);
                                left: calc(50% - .5em);
                                width: 1em;
                                height: 1em;
                                border: #296596 1px solid;
                                opacity: 1;
                            }
                            100% {
                                top: calc(50% - 1.5em);
                                left: calc(50% - 1.5em);
                                width: 3em;
                                height: 3em;
                                border: #296596 1px solid;
                                opacity: 0;
                            }
                        }
                    }
                }
                //Modifiers
                &.parallax-container {
                    div.img-container {
                        position: relative;
                        padding-left: 7vw;
                        img {
                            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                            //Modifiers
                            &.front {
                                position: absolute;
                                z-index: 2;
                                //left: 2vw;
                                top: 10%;
                                width: 68%;
                                left: 8%;
                            }
                            &.back {
                                box-shadow: 10px 14px 12px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                                width: auto;
                                margin-left: auto;
                                width: 80%;
                            }
                        }
                    }
                }
                &.has-padding {
                    padding-top: 90px;
                    //Modifiers
                    &.is-small-padding {
                        padding-top: 55px;
                    }
                }
                &.has-left-gutter {
                    margin-left: 6.39vw;
                }
            }
        }
    }
    //Modifiers
    &.is-contact-form {
        div.container-fixed {
            border-top: 2px solid #f1f1f2;
            padding-top: 100px;
            div.flex-container-row {
                align-items: initial;
                & > div:first-child {
                    padding-top: 25px;
                }
                & > div:last-child {
                    padding-top: 50px;
                }
            }
        }
    }
    //Responsive
    @media (max-width: 1536px) {
        .container-fixed {
            .flex-container-row {
                div {
                    span.label {
                        font-size: 1.6rem;
                    }
                    h2 {
                        font-size: 2.8vw;
                    }
                    h4 {
                        font-size: 1.25vw;
                    }
                    p {
                        font-size: 1.1vw;
                        font-weight: 100;
                        width: 100%;
                        max-width: 495px;
                        line-height: 1.85;
                    }
                    a {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}
