div.step2 {
	overflow: hidden;

	h1, h2, h3, h4, p {
		margin: 0px;
	} 


	div#qrcode img {
		display: block;
		margin: 25px auto;
	}

	p#barcode-error span {
		color: red;
	}
	button.add-layer.disabled {
		opacity: 0.4 !important;
	}

	div.view-wrap{
		position: absolute;
		top: 4.5vw;
		right: 22vw;
		div.undoredo {
			position: relative;
			cursor: pointer;
			img {
				padding: 0.17vw;
				width: 2.7vw;
				opacity: 0.6;
			}
			p {
				font-size: 1vw;
				text-align: center;
				margin-bottom: 0.5vw;
				color: $g8;
			}
		}
	}

	div.column {

		position: relative;
		height: calc(100vh - 3vw);
		padding: 0;
		overflow: hidden;

		&.white {
			background: white !important;
		}

		div.zoom {
			display: none;
			position: absolute;
			bottom: 2vw;
			left: 4.5vw;
			p {
				font-size: 0.8vw;
				text-align: center;
				margin-bottom: 0.5vw;
			}
			div.zoom-icons {
				img {
					padding: 0.4vw;
					width: 2.7vw;
				}
				cursor: pointer;
			}
		}

		div#WebGL-output {
			position: absolute;
			left: 3vw;
			top: 7.5vw;
			width: 100%;
			height: 70%;
			/*border: 10px solid green;*/
		}
		/*
		#main-canvas{
			visibility: hidden;
		}
		#main-canvas.loaded{
			visibility: visible;
		}   
		*/
		/*#main-canvas{
                width: 80vw;
                height: 80vw;
            }*/
		/*      @media (min-width:601px){
                    div#WebGL-output {
                        position: absolute;
                        left: 3vw;
                        top: 7.5vw;
                        width: 100%;
                        height: 70%;
                    }
                }*/

		div.top-section, div.bottom-section {
			position: relative;
		}

		div.top-section {
			background: white;
			height: 86vh;
			div.material-container {
				padding-top: 1vh;
			}
		}

		div.bottom-section {
			height: 42.5vh;
			div.column-transition {
				width: calc(200% - 5.6vw);
				display: flex;
				flex-direction: row;
				transform: translateX(-50%);
				div.c {
					width: 50%;
				}
			}
			#barcode {
				cursor: pointer;
			}
			div.absolute-bottom {
				opacity: 0;
				position: absolute;
				bottom: 5.5vw;
				left: 0;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				img {
					width: 7.5vw;
					height: auto;
					margin: 0 0.5vw;
					cursor: pointer;
				}
			}
		}

		div.error-message {
			background: $r1;
			color: white;
			position: absolute;
			left: calc(50% - 17.5vw);
			top: 30%;
			width: 35vw;
			padding: 1vw;
			opacity: 0;
			pointer-events: none;
			box-shadow: 2.5px 2.5px 5px rgba(0,0,0,0.25);
			border-radius: 5px;
			&.visible {
				opacity: 1;
				pointer-events: visible;
			}
			p {
				text-align: center;
				font-size: 1.5vw;
			}
		}

		img.save-draft {
			position: absolute;
			bottom: 2.5vw;
			left: calc(50% - 4vw);
			width: 8vw;
			cursor: pointer;
		}

		div.undoredo {
			position: absolute;
			top: 2vw;
			right: 4.5vw;
			img {
				padding: 0.3vw;
				width: 2.7vw;
			}
			p {
				font-size: 1vw;
				text-align: center;
				margin-bottom: 0.5vw;
			}
			.undoredo-icons {
				width: 50%;
				float: left;
			}
			cursor: pointer;
		}
		/*
		div.canvas-warper {
			//Responsive 
			@media (max-height: 730px) {
				top: 26vh!important;
			}
			@media (max-height: 675px) {
				top: 28vh!important;
			}
			@media (max-height: 615px) {
				top: 30vh!important;
			} 
		}
		*/
		div.indesignbox {
			position: fixed;
			/*//left: 29.5vw;
			//top: 11vw;*/
			width: calc(100vw - 40vw);
			height: 32.5vw;
			background: white;
			box-shadow: 3px 3px 10px -2px #858f97;
			z-index: 998;
			opacity: 0;
			pointer-events: none;
			padding: 0.5vw;
			overflow: visible !important;
			div.element-container {
				padding: 0.3vw;
				border: 0.3vw solid transparent;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				user-drag: none;
				user-select: none;
				-moz-user-select: none;
				-webkit-user-drag: none;
				-webkit-user-select: none;
				-ms-user-select: none;

				&.active {
					margin: 0;
					border: 0.3vw dashed $g4;
					z-index: 10;
				}
			}

			div.security-label {
				div.pointer-circle { 
					left: 16.5%;
					margin-left: 29px;
					top: 5px;
					position: absolute;
					width: 9px;
					height: 9px;
					border-radius: 4px;
					background-color: #fff;
					border: 1px solid #919699;
					z-index: 5;
				}

				div.pointer-line-fg {
					left: 16.5%;
					margin-left: 33px;
					width: 1px;
					height: 24px;
					top: -16px;
					position: absolute;
					border-left: 1px dashed black;
					opacity: .6;
					z-index: 1;
				}

				div.security-text {
					left: 16.5%;
					top: -4vh;
					position: absolute;
					background: #fff;
					padding: 2px 5px;
					color: #666;
					font-size: 12px;
					border: 1px solid #999;
					-webkit-user-select: none; /* Safari, iOS, and Android */
					-moz-user-select: none; /* Firefox */
					-ms-user-select: none; /* Internet Explorer/Edge */
					user-select: none;
					background: white;
					z-index: 2; 
					cursor: default;
				}
				//Responsive
				@media (max-height: 560px) {
					div.security-text {
						left: -80px;
						top: 44.5%;
						//Line
						&:after {
							content: "";
							display: block;
							left: initial;
							right: -1.55vw;
							position: absolute;
							top: 10px; 
							height: 1px;
							width: 1.5vw;
							border-bottom: 1px dashed black;
						}
					}
					div.pointer-line-fg {
						display: none;
					}
					div.pointer-circle {
						left: -3px;
						margin: 1%;
						top: 44.7%;
					}
				}
			}

			div.inside-indesignbox {
				position: relative;
				width: 100%;
				height: 30.5vw;
				border: 1px dashed $g3;
				overflow: auto;
			}

			div.safety-info {
				left: calc(16% + 100px);
				top: -80px;
				position: absolute;
				background: #fff;
				padding: 2px 5px;
				color: #666;
				font-size: 12px;
				border: 1px solid #999;
				-webkit-user-select: none; /* Safari, iOS, and Android */
				-moz-user-select: none; /* Firefox */
				-ms-user-select: none; /* Internet Explorer/Edge */
				cursor: default ;
				width: 150px;
				height: 80px;
				white-space: normal;
				-webkit-box-shadow: -3px 3px 14px -2px rgba(0,0,0,0.10);
				-moz-box-shadow: -3px 3px 14px -2px rgba(0,0,0,0.10);
				box-shadow: -3px 3px 14px -2px rgba(0,0,0,0.10);

				background: rgb(242,242,242); /* Old browsers */
				background: -moz-linear-gradient(45deg, rgba(242,242,242,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(45deg, rgba(242,242,242,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(45deg, rgba(242,242,242,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

				display: none;
			}
			//Responsive 
			/*
			@media (max-height: 730px) {
				top: 26vh!important;
			}
			@media (max-height: 675px) {
				top: 28vh!important;
			}
			@media (max-height: 615px) {
				top: 30vh!important;
			} 
			*/
		}

		div.selection {
			position: absolute;
			width: 39%;
			left: 22vw;
			top: 1.5vw;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			&.selection-special {
				width: 80.5vw;
				left: 0;
				opacity: 0;
				pointer-events: none;
				div.option {
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					margin-top: 10px;
					&:hover{

					}
					img {
						width: 2.75vw;
					}
					p {
						padding-top: 0.3vw;
						font-size: 1.5vw;
						padding-left: 0.5vw;
						text-transform: capitalize;

					}
				}
			}
			div.option {
				p {
					//font-weight: 300;
					font-size: 1vw;
					text-align: center;
					margin-bottom: 0.5vw;
					color: $g8;
				}
				img {
					width: 2.7vw;
					&.inactive {
						display: block;
					}
					&.active {
						display: none;
					}
				}
				&.active {
					p {
						color: $o1;
					}
					img {
						&.inactive {
							display: none;
						}
						&.active {
							display: block;
						}
					}
				}
				&:hover{
					cursor:pointer;
					p {
						color: $o1;
					}
				}
			}
		}


		div.selection_side {
			position: relative;
			left: 2.1vw;
			top: 1vw;
			display: flex;
			flex-direction: row;
			margin-top: 1vw;

			&.selection-special-side {
				opacity: 1;
				pointer-events: none;
				div.option {
					display: flex;
					flex-direction: row-reverse;
					height: 100%;
					max-height: 25px;
					align-items: center;
					margin-top: 10px; 
					img {
						width: 1.6vw;
					}
					p {
						padding-top: 0.3vw;
						font-size: 0.9vw;
						padding-left: 0.5vw;
						text-transform: capitalize;
					}
				}
			}
			div.option {
				p {
					//font-weight: 300;
					font-size: 0.8vw;
					text-align: center;
					margin-bottom: 0.5vw;
					color: $g8;
				}
				img {
					width: 0.5vw;
					&.inactive {
						display: block;
					}
					&.active {
						display: none;
					}
				}
				&.active {
					p {
						color: $o1;
					}
					img {
						&.inactive {
							display: none;
						}
						&.active {
							display: block;
						}
					}
				}
			}
		}


		button.button {
			display: block;
			margin: 0.1vw auto 0.25vw auto;
			width: 60%;
			height: 2.3vw;
			background: #07bb38;
			color: white;
			text-align: center;
			border: none;
			border-radius: 0.1vw;
			font-size: 1.1vw;
			&:hover {
				background-color: #00731f !important;
			}
		}

		button.goBack {
			width: 20%;
			position: absolute;
			left: 55%;
			top:  5.2vw;
		}

		button.save {
			width: 20%;
			position: absolute;
			left: 80%;
			top:  5.2vw;
			background: white;
			color: $o1;
			border: 1px solid $g4;
		}
		div.save-btn-wrap{
			text-align: center;
			position: fixed;
			bottom: 3.5vh;
			width: 50%;
			button {
				color: white;
				background: #56b5f5;
				padding: 0.5vw;
				border: 0.1vw solid #CBCBCB;
				width: 40%;
				margin: 0vw 0.25vw;
				font-size: 0.75vw;
				cursor: pointer;
				&:hover{
					background: #2a6696;
				}
			}
		}
		div.save-btn{
			text-align: center;
			button {
				color: white;
				background: #56b5f5;
				padding: 0.5vw;
				border: 0.1vw solid #CBCBCB;
				width: 40%;
				margin: 0vw 0.25vw;
				font-size: 0.75vw;
				cursor: pointer;
				&:hover{
					background: #2a6696;
				}
			}
		}
		div.save-btn-wrap-panel{
			text-align: center;
			position: absolute;
			top: 6.5vh;
			left: 0wv;
			width: 50%;
			margin-left: -3vw;

		}
		div.view-changer-button{

			top: 1.5vw;
			.title{
				font-size: 1vw;
				display: block;
				margin-bottom: 0.5vw;
				color: $g8;
			}
			button {
				border: none;
				color: white;
				background: $o1;
				padding: 0.5vw;
				width: 16%;
				margin: 0vw 0.25vw;
				font-size: 1vw;
				cursor: pointer;
				transition: all 0.2s ease;

				&:hover{
					background: #2a6696;
				}
				&.view-3d{

				}
				&.view-2d{
					&:hover{
					}
				}
			}
			.view-dimension{
				.view-icon{
					display: inline-block;
					cursor: pointer;
					img {
						padding: 0.09vw;
						width: 2.7vw;
						opacity: 0.7;
					} 
					&.view-2d{
						img{
							margin-left: 3px;
						}
					}
					&.view-3d{
						img{
							margin-right: 3px;
						}
					}
				}
			}
		}
		div.btn-wrap {
			padding: 1.25vw 0 1.75vw 0;
			padding-top: 1.25vw;
		}  

		div.go-back {
			background: #F2F2F2;
			display: none;
			position: relative;
			padding-bottom: 0.5vw;
			padding-top: 0.25vw;
			padding-left: 0.4vw;
			h2 {
				border-bottom: none;
			}
			div.plus {
				cursor: pointer;
				background: white;
				border-radius: 50%;
				width: 1.25vw;
				height: 1.25vw;
				padding-top: 0.003vw;
				padding-right: 0.003vw;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: 1.25vw;
				top: 1.25vw;
				font-size: 1.25vw;
				color: $g12;
				transition: all 0.2s ease;
				&:hover{
					width: 1.4vw;
					height: 1.4vw;
				}
			}
		}

		div.relative-container {
			position: relative;
			background: #F2F2F2;

			img.svg {
				position: absolute;
				left: calc(50% - 0.9vw);
				top: -0.9vw;
				width: 1.8vw;
				height: 1.8vw;
				background: $g11;
			}

			h2, h3 {
				border-bottom: none;
				margin-top: 0vw;
				margin-bottom: 0vw;
				margin-left: 0vw;
				padding-bottom: 0.25vw;
				padding-left: 0vw;
				opacity: 0.25;
				cursor: not-allowed;
				//font-weight: 300;
				font-size: 0.75vw;
			}

			h2.header.no-bottom {
				font-size: 0.80vw;
				padding-top: 1vw;
				padding-bottom: 1.4vw;
				position: relative;
				opacity: 1 !important;
				&.special-h2{
					padding-bottom: 1.6vw;
				}
			}

			h3 {
				position: absolute;
				left: 0vw;
				top: 4.7vw;
			}

			div.border {
				border-top: 0.1vw solid $g3;
				padding-bottom: 0.5vw;
				margin: 0vw -1.5vw;
				margin-bottom: 0.25vw;
			}

			&.top-border {
				margin: 0vw 0vw;
				padding: 0vw 0vw 0vw 0vw;
				border-bottom: none;

				div.top-wrap {
					margin: 0vw 1.4vw;
					position: relative;
					height: 8vh;
				}
			}
			div.dimension2d3d {
				position: absolute;
				right: 0vw;
				top: 1.125vw;
				display: none;
				flex-direction: row;
				cursor: pointer;
				opacity: 0.5;
				cursor: not-allowed;
				pointer-events: none;
				p {
					padding: 0.25vw;
					font-size: 0.9vw;
					font-weight: 500;
					color: $g10;
					&.active {
						color: $o1;
					}
				}
			}
		}

		&.bg-grey {
			background: $g11;
			border-right: 0.1vw solid $g3;
		}

		div.layers p.bottom-border.sub-header {
			opacity: 0;
		}

		h2.header {
			font-size: 0.80vw;
			font-weight: 400;
			padding: 1.25vw 1vw 1vw 1vw;
			border-bottom: 0.1vw solid $g3;
			cursor: default;
			&.inset {
				margin: 0vw 1.4vw;
				padding: 2vw 0vw 1.6vw 0vw;
			}
		}

		div.bottom-border {
			margin: 0vw 1.4vw;
			border-bottom: 0.1vw solid $g3;
		}

		div.bottom-border-lable {
			font-size: 0.8vw;
			font-weight: 300;
			margin: 0.85vw 7.2vw -0.5vw;
			color: $g8;

		}

		p.copy {
			font-size: 0.7vw;
			font-weight: 300;
			padding: 0vw 0vw 1.25vw 0;
			color: $g10;
			cursor: default;
		}

		p.sub-header {
			font-size: 0.9vw;
			font-weight: 400;
			padding: 0.90vw 1.4vw 1.3vw;
			color: black;
			cursor: default;

			&.bottom-border {
				padding: 0.65vw 0vw;
				margin: 0vw 1.4vw;
				border-bottom: 0.1vw solid $g3;
				opacity: 0.5;
			}
			&.top-border {
				border-top: 0.1vw solid $g3;
				margin: 0vw 1.4vw;
				padding: 0.75vw 0vw;
				margin-top: 0.42vw;
			}
		}

		div.dimensions {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: -0.25vw 1.4vw 0vw 1.4vw;
			border-bottom: 0.1vw solid $g3;
			flex-wrap: wrap;
			div.dimension-input {
				width: calc(33.33% - 1.1vw);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;
				p {
					font-weight: 500;
					font-size: 0.9vw;
					margin-bottom: 1.1vw;
					color: $g8;
				}
				div.input-wrapper { 
					position: relative;
					input {
						width: calc(100%);
						padding: 0.40vw;
						padding-left: 0.2vw;
						padding-right: 1.25vw;
						font-size: 1vw;
						text-align: left;
						padding-left: 0.65vw;
						font-weight: 400;
						background-image: url("/img/misc/in.png");
						background-repeat: no-repeat;
						background-position: 42.5% 30%;
						background-size: 0.25vw 0.25vw;
						border: 0.1vw solid $g12 !important;
						border-radius: 0.15vw;
						&.longer {
							background-position: 50% 30%;
						}
						&.longerr {
							background-position: 60% 30%;
						}
						&.shorter {
							background-position: 35% 30%;
						}
						&.longest {
							background-position: 70% 30%;
						}
					}
					div.arrows-wrapper {
						position: absolute;
						top: 0;
						right: 0;
						display: flex;
						flex-direction: column;
						height: 100%;
						justify-content: space-evenly;
						padding: 8px 5px;
						box-sizing: border-box;
						div.arrow-top {
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 0.40vw 0.40vw 0.40vw;
							border-color: transparent transparent $o1 transparent;
							cursor: pointer;
							&:hover {
								border-color: transparent transparent $o2 transparent;
							}
							&:active {
								border-color: transparent transparent $g3 transparent;
							}
						}
		
						div.arrow-bottom {
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0.40vw 0.40vw 0 0.40vw;
							border-color: $o1 transparent transparent transparent;
							cursor: pointer;
							&:hover {
								border-color: $o2 transparent transparent transparent;
							}
							&:active {
								border-color: $g3 transparent transparent transparent;
							} 
						}
					}
				}
			}
			div.notation {
				padding: 3vh 0 5vh;
				width: 100%; 
				p {
					margin: 0;
					font-weight: 400;
					color: lighten($g8, 10%); 
					font-size: 0.82vw;  
					span.underline-text { 
						text-decoration: underline; 
					} 
				}
			}
		}

		div.bottom-columns {
			height: calc(100vh - 6vw);
			width: 40vw;
			display: flex;
			flex-direction: row;
			transform: translateX(-19.5vw);

			div.select-box-wrap {
				display: flex;
				flex-direction: row;
				margin: 0.25vw 0 1vw 0;
			}

			div.options-wrap {
				display: flex;
				flex-direction: row;
				margin: 0.5vw 0 1.25vw 0;
				margin-left: 1.4vw;
				justify-content: flex-start;
				align-items: center;
				img {
					margin: 0 1.25vw 0 0;
					width: 1vw;
					cursor: pointer;
				}
				&.first {
					margin-bottom: 1vw;
					img {
						&:nth-child(1) {
							width: 0.75vw;
						}
						&:nth-child(2) {
							width: 0.8vw;
						}
						&:nth-child(3) {
							width: 0.9vw;
							padding-top: 0.1vw;
						}
						&:last-of-type {
							width: 1vw;
						}
					}
				}
				&.tight {
					flex-wrap: wrap;
					width: 6.75vw;
					margin-bottom: 0.5vw;
					img {
						width: 1.5vw;
						margin: 0 0.75vw 1vw 0;
					}
				}
			}

			textarea.special-ta {
				margin: 0vw 1.4vw 0.5vw 1.4vw;
				background: $g2;
				padding: 0.5vw;
				width: calc(100% - 2.8vw);
				height: 6vw;
				border: none;
				resize: none;
			}

			img.save-layer {
				display: block;
				margin: 0.75vw auto;
				width: 7.5vw;
				cursor: pointer;
			}

			div.select-box {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				border: 0.1vw solid $g3;
				border-radius: 0.2vw;
				height: 2vw;
				width: 100%;
				margin-right: 0;
				margin-left: 1.4vw;
				&:last-of-type {
					margin-right: 1.4vw;
				}
				p {
					font-size: 0.8vw;
					margin-left: 0.5vw;
				}
				img {
					margin-right: 0.5vw;
					cursor: pointer;
				}
				&.large {
					width: 85%;
				}
				&.small {
					width: 25%;
				}
			}

			.upload-image {
				margin: 0.75vw auto 1.5vw auto;
				width: calc(100% - 2.8vw);
				height: 9.5vw;
				border: 0.1vw dashed $g3;
				background: $g2;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
				.image-loader {
					@include loader07($size: 10px, $duration: 1s, $align: middle);
					top: 3%;
					display: none;
				}
				p {
					font-size: 1vw;
					color: $g8;
					margin-bottom: 1vw;
					//Childs
					&:first-of-type {
						padding-top: 0.5vw;
					}
					&.upload-image-button {
						transition: all 0.2s ease;
						padding: 10px 20px;
						background-color: $o1;
						color: white;
						cursor: pointer;
						&:hover{
							background-color: $o2;
						}
					}
				}
				img {
					width: 50%;
				}
				&.uploading{
					.image-loader{
						display: block;
					}
					p, img {
						display: none;
					}
				}
				&.dragover{
					border: 0.1vw dashed $o1;
				}
			}
			p.upload-error {
				color: $r1;
				margin: 0;
				text-align-last: center;
				text-transform: uppercase;
				font-size: 8px;
				padding: 0 15px 5px;
				text-align: center;
				display: none;
				//Modifiers
				&.is-visible {
					display: block;
				}	
			}
			div.shapes {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				padding: 1.4vw;
				div.shape {
					width: 50%;
					height: 6.5vw;
					border-right: 0.1vw solid $g3;
					border-bottom: 0.1vw solid $g3;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: 40%;
						//States
						&:hover {
							cursor: pointer;
						}
					}
					&.rectangle {
						img {
							width: 50%;
						}
					}
					&:nth-child(even) {
						border-right: none;
					}
					&:nth-child(3) {
						border-bottom: none;
					}
					&:nth-child(4) {
						border-bottom: none;
					}
				}
			}

			div.shape-inputs-2 {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 0 1.4vw 0.75vw 1.4vw;
				img.color {
					width: 3vw;
				}
			}

			div.shape-inputs {
				display: flex;
				flex-direction: row;
				margin: 0 1.4vw 0.75vw 1.4vw;
				div.input {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: calc(50% - 2.7vw);
					p {
						margin: 0;
						font-size: 0.75vw;
					}
					input {
						margin: 0.25vw 0vw;
						height: 1.85vw;
						width: 100%;
						border: 0.1vw solid $g12 !important;
						padding: 0 0.5vw;
					}
					&:nth-child(1) {
						margin-right: 0.5vw;
					}
					&:nth-child(2) {
						margin-left: 0.5vw;
						margin-right: 1vw;
					}
					&.full-left {
						width: calc(100%);
						p {
							width: 100%;
							text-align: left;
						}
					}
				}
				img {
					margin-top: 1vw;
					width: 2vw;
				}
				&.full-input {
					div.input {
						width: calc(50% - 0.5vw);
					}
				}
			}

			div.bc {
				position: relative;
				width: 50%;
				&:first-child{
					padding-left: 2px;
				}
				&:nth-child(2){
					margin-left: -5px;
				}
				img.back {
					cursor: pointer;
					display: block;
					width: 2.2vw;
					padding: 0.5vw;
					position: absolute;
					right: -0.4vw;
					top: 1.2vw;
					transition: all 0.2s ease;
					&:hover{
						padding: 0.4vw;
					}
				}
				img.placeholder {
					display: block;
					/*width: calc(100% - 2.8vw);*/
					width: auto;
					max-height: 180px;
					margin: 1vw auto;
					&.less-space {
						margin-top: 0.25vw;
					}
					&.selected-image{
						max-height: 180px;
					}
				}
			}

			canvas#barcode {
				position: relative;
				left: 1vw;
				max-width: 90%;
			}

			div#qrcode img {
				margin: 20px auto;
			}

		}

		div.materials {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: stretch;
			max-width: 16.625vw;
			margin: 0 auto;
			//border-bottom: 0.1vw solid $g3;
			padding-bottom: 6.7vw;
			position: relative;
			div.material-input {
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;
				padding: 0;
				transition: all 0.3s ease;

				div.icon-container {
					width: 3.5vw;
					height: 3.5vw;
					border-radius: 50%;
					background: white;
					margin: 0 auto;
					img.default,
					img.hovered-selected {
						width: 100%;
						height: 100%;
					}
					img.hovered-selected {
						display: none;
					}
				}   

				&.active {
					div.icon-container {
						img.default {
							display: none;
						}
						img.hovered-selected {
							display: block;
						}
					}
					p {
						color: $o1;
					}
				}

				&:hover{
					div.icon-container {
						img.default {
							display: none;
						}
						img.hovered-selected {
							display: block;
						}
					}
					p {
						color: $o1;
					}
				}

				p {
					font-weight: 500;
					font-size: 0.9vw;
					color: $g8;
					width: 100%;
					max-width: 3.51vw;
					text-align: center;
					margin: 0 auto 0.8vw;
				}

			}
		}

		div.thickness {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 0vw 1vw 0vw 1vw;
			padding-bottom: 0.5vw;
			div.thickness-input {
				width: 50%;
				text-align: center;
				//padding-bottom: 1vw;
				cursor: pointer;

				&.active, &:hover {

					div.circle {
						border: 0.1vw solid $o1;
					}

					p {
						color: $o1;
					}

				}
				div.circle {
					width: 3.1vw;
					height: 3.1vw;
					border-radius: 50%;
					border: 0.1vw solid $g3;
					margin: 0 auto;
					background: white;
					img {
						width: 100%;
						height: 100%;
					}
				}
				p {
					font-size: 0.9vw;
					font-weight: 500;
					margin-bottom: 0.8vw;
					color: $g8;
				}
				&:nth-of-type(2) {
					position: relative;
					left: 0.25vw;
				}

			}
		}

	}

	img.template-img {
		width: 7.5vw;
		display: block;
		margin: 1vw auto;
	}

	p.copy {
		color: $g10;
		font-size: 0.7vw;
		margin: 1.4vw 1.4vw 0vw 1.4vw;
		margin-top: 0vw;
		&.squished {
			width: 12vw;
			margin: 1.4vw auto 0vw auto;
			text-align: center;
			font-size: 0.9vw !important;
		}
	}

	div.box-designer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1.4vw 2vw;
		padding-bottom: 0vw;
		opacity: 0.5;
		pointer-events: none;
		cursor: not-allowed;

		div.box-design {
			width: calc(33.33% - 0.7vw);
			position: relative;
			margin-bottom: 0.85vw;
			cursor: pointer;
			border: 1px rgb(197, 195, 195) dashed;
			&:hover{
				border: 1px #8D8B8B dashed;
				p {
					color: #8D8B8B;
				}
			}
			.default-panel-icon{
				&.one{
					width: 60%;
					opacity: 0.8;
				}
				&.two{
					width: 43%;
				}
				&.three{
					width: 48%;
				}
				&.four{
					width: 50%;
				}
				&.five{
					width: 50%;
				}
				&.six{
					width: 80%;
				}
			}
			img{
				&:first-child{
					visibility: hidden;
				}
			}

			img:nth-of-type(1) {
				width: 100%;
			}
			img:nth-of-type(2) {
				position: absolute;
				width: 50%;
				margin: auto;
				left: 0;
				right: 0;
				bottom: 37%;
			}
			p {
				text-align: center;
				margin: auto;
				position: absolute;
				font-size: 0.75vw;
				height: 1vw;
				left: 0;
				right: 0;
				top: 2.75vw;
				bottom: 0;
			}
		}

	}

	div.pricing {
		padding: 2vw 2vw;
		border: 0.1vw solid $g3;
		position: absolute;
		bottom: 0;
		width: 87%;
		right: 0;
		box-sizing: border-box;
		//max-height: 345px;
		overflow: hidden;
		&.prices-box{
			//visibility: hidden;
			-webkit-transition: 0.2s linear all;
			transition: 0.2s linear all;
			transition-delay: 0.2s;
			opacity: 0;
			top: 7.9vh;
			bottom: inherit;
			padding-bottom: 0.8vw;
			padding-top: 0.5vw;
		}
		&.visible{
			-webkit-transition: 0.2s linear all;
			transition: 0.2s linear all;
			transition-delay: 0.2s;
			//visibility: visible;
			opacity: 1;
		}



		hr {
			border-color: $o1;
		}

		h2.header {
			padding-top: 0vw;
			padding-left: 0.25vw;
			padding-bottom: 0.5vw;
		}


		div.floating-band {
			position: absolute;
			left: 0.5vw;
			width: calc(100% - 1vw);
			height: 1.6vw;
			background: $g11;
			z-index: 9;
			top: 11vh;
			&.two {
				top: 14vh;
			}
			&.three {
				top: 17.5vh;
			}
			&.four {
				top: 20.5vh;
			}
			&.five {
				top: 24vh;
			}
			&.six {
				top: 27vh;
			}
			&.seven {
				top: 30.5vh;
			}
		}

		div.pricing-columns {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			div.pricing-column { 
				position: relative;
				z-index: 10;
				width: 50%;
				margin: 0px;

				&:first-of-type {
					border-right: 0.1vw solid $g3;
				}

				&:nth-of-type(3) {
					border-right: 0.1vw solid $g3;
					//border-bottom: 0.1vw solid $g3;
					padding: 0.5vw 0vw;
					padding-bottom: 0;
				}

				&:nth-of-type(4) {
					padding: 0.5vw 0vw;
					padding-bottom: 0;
				}

				h3 {
					font-size: 0.8vw;
					border-bottom: 0.1vw solid $g3;
					padding: 0.5vw 0vw 0.5vw 0vw;
					text-align: center;
					font-weight: 500;
					color: #5c5c5c;
					margin: 0px;
				}

				p {
					font-size: 0.85vw;
					color: $g8;
					font-weight: 400;
					text-align: center;
					padding: 0.35vw;
					margin: 0px;
					line-height: 16px;
					a {
						cursor: pointer;
					}
					//Modifiers
					&.active{
						background-color: #f2f2f2;
						transition: background-color 0.5s ease;
					}
				}

				//Modifiers
				$row: 0;
				$child: 1;
				@while $row < 20 {
					&.active-child-#{$row} {
						p:nth-child(#{$child}) {
							background-color: #f2f2f2;
							transition: background-color 0.5s ease;
						}
					}
					$row: $row + 1; 
					$child: $child + 1; 
				} 
			}

		}

		div.quote {
			margin-top: 3px;
			margin-bottom: 6px;
			h4 {
				font-size: 1vw;
				padding: 0.5vw 0.25vw;
				font-weight: 400;
				margin: 0px;
				padding-left: 9px;
				padding-right: 8px;
				&#design-cost{
					padding-top: 0.5vw;
					padding-bottom: 0.7vw;
				}
				&#order-total{
					padding-bottom: 0.7vw;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}
			}
			div.title-pricebox-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				height: 4.6vh;
				box-sizing: border-box;
				margin-bottom: 2.2vh;
				h4#price-per-box{
					border: solid 1px $o1;
					flex: 2;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					position: relative;
					padding: 0 10px 0;
					i.fa-spinner,
					span.price {
						color: $o1;
					}
					i.fa-spinner {
						top: 13px;
					}
				}
				span.question {
					flex: 1;
					max-width: 25px;
					text-align: center;
					color: $o2;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					//States
					&:hover {
						cursor: pointer;
					}
				}
			}

			hr {
				margin: 2px 0;
			}

			div.quote-box {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 0.65vw;

				span {
					font-size: 1vw;
					flex: 3;
					padding: 0.5vw 0.25vw;
					padding-left: 9px;
					&.m-right {
						margin-right: 1.5vw;
					}
				}

				div.quote-input {
					display: flex;
					flex-direction: row;
					align-items: center;
					position: relative;
					height: 4vh;
					input {
						margin-left: 0.25vw;
						padding-right: 1.6vw;
						text-align: right;
						width: 5.5vw;
						font-size: 1vw;
						font-weight: 500;
						color: $g4;
						height: 100%;
						//border: 0.1vw solid $g12 !important;
						outline: none;
						//border-radius: 0.15vw;
						border: 1px solid $g6;
						&:focus{
							border-color: $g6 !important;
						}
					}

					div.arrows-wrapper {
						height: 100%;
						display: flex;
						position: absolute;
						flex-direction: column;
						justify-content: space-between;
						right: 0;
						padding: 0.9vh 0.3vw;
						div.arrow-top {
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 0.4vw 0.4vw 0.4vw;
							border-color: transparent transparent $o1 transparent;
							cursor: pointer;
							&:hover {
								border-color: transparent transparent $o2 transparent;
							}
							&:active {
								border-color: transparent transparent $g3 transparent;
							}
						}
	
						div.arrow-bottom {
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0.4vw 0.4vw 0 0.4vw;
							border-color: $o1 transparent transparent transparent;
							cursor: pointer;
							&:hover {
								border-color: $o2 transparent transparent transparent;
							}
							&:active {
								border-color: $g3 transparent transparent transparent;
							}
						}
					}


					p {
						margin-left: 0.5vw;
						color: $g4;
					}

				}

			}

		}
		div.buttons {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-end;
			li{
				position: relative;
				a {
					height: 4.5vh;
				}
				&#save-button{
					//padding-left: 0.25vw;
					padding-right: 5px;
					&:hover{
						i {
							color: $o2;
						}
					}
					a {
						font-size: 1vw;
						padding-left: 11px;
						&:hover{
							color: $o2;
							border-color: $o2;
						}
					}
					i {
						-webkit-transition: 0.1s linear all;
						transition: 0.1s linear all;
						transition-delay: 0.1s;
						position: absolute;
						left: 17px;
						font-size: 1.3vw;
						top: 50%;
						transform: translate(0%, -50%);
						color: $o1;
						&:hover{
							color: $o2;
						}
					}
				}
				&#add-to-cart-button {
					background-color: $o1;
					position: relative;
					box-sizing: border-box;
					a {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						border: none;
						padding-left: 15px;
						font-size: 0.9vw;
						height: 4.5vh;
						background-color: transparent;
						box-sizing: border-box;
						overflow: hidden;
						//Color overlay for feedback on checkout
						&:before {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 0;
							height: 100%;
							background-color: $gr1;
							overflow: hidden;
							box-sizing: border-box;
							will-change: width;
						}
						//States
						&:hover{
							background-color: $o2;
						}
						//Modifiers
						&.in-progress {
							&:before {
								animation: progress 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
							}
							//Modifiers
							&.has-error {
								&:before {
									background-color: $r1;
								}
							}
						}
						&.is-disabled {
							background-color: $g8;
						}
						span.checkout-separator {
							position: relative;
							z-index: 2;
							color: $w1;
							padding-left: 7px;
							padding-right: 1px;
							i {
								padding-right: 10px;
								font-size: 1.2vw;
								color: white;
							}
						}
					}
				}
			}

			button {
				padding: 0.5vw;
				border: 0.1vw solid $g3;
				width: 40%;
				color: $g8;
				background: none;
				margin: 0vw 0.25vw;
				font-size: 0.75vw;
				cursor: pointer;

				&:hover {
					background: $o2;
					color: white;
				}

				&:nth-of-type(2) {
					background: $o1;
					color: white;
					&:hover {
						background: $o2;
						color: white;
					}
				}
			}
		}
	}

	div.order {

		margin: 1.5vw 1.25vw 0vw 0vw;
		padding: 0.5vw;
		border: 0.1vw solid $g3;
		height: 18.25vw;

		h2.header {
			text-align: center;
			padding-top: 0.8vw;
		}

		div.order-header {
			display: flex;
			flex-direction: row;
			h3 {
				font-size: 0.7vw;
				border-bottom: 0.1vw solid $g3;
				font-weight: 500;
				padding: 0.5vw;
				color: $g8;
				text-align: center;
				margin: 0px;

				&:nth-of-type(1) {
					width: calc(32.5% - 0.05vw);
					padding-left: 0.85vw !important;
					text-align: left !important;
				}

				&:nth-of-type(2) {
					width: 20%;
					border-left: 0.1vw solid $g3;
				}

				&:nth-of-type(3) {
					border-left: 0.1vw solid $g3;
					width: calc(20%);
				}

				&:nth-of-type(4) {
					border-left: 0.1vw solid $g3;
					width: 27.5%;
				}
			}
		}

		div.order-columns {

			display: flex;
			flex-direction: row;
			overflow: auto;
			height: 7vw;

			div.order-column {

				height: 100%;
				overflow: visible;
				width: calc(25% + 1px);
				display: flex;
				flex-direction: column;
				align-items: center;

				&:nth-of-type(1) {
					width: 32.5%;
				}

				&:nth-of-type(2) {
					width: 20.1%;
				}

				&:nth-of-type(3) {
					width: 20.1%;
				}

				&:nth-of-type(4) {
					width: 27.4%;
				}

				border-right: 0.1vw solid $g3;

				&:last-of-type {
					border: none;
				}

				h3 {
					font-size: 0.7vw;
					border-bottom: 0.1vw solid $g3;
					font-weight: 300;
					padding: 0.5vw;
					text-align: center;
				}

				p {
					font-size: 0.7vw;
					padding: 1vw 0.25vw 1vw 0.85vw;
					width: 100%;
					height: 3vw;
					&.center {
						text-align: center;
						padding: 1vw 0.25vw;
					}
					img.icon {
						width: 0.9vw;
						&.edit {
							position: relative;
							top: 0.1vw;
						}
						&.delete {
							width: 0.7vw;
						}
					}
				}

			}

		}

		div.total {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0.5vw;
			border-top: 0.1vw solid $g3;
			border-bottom: 0.1vw solid $g3;
			overflow: auto;
			height: auto;
			h3 {
				font-size: 1vw;
				color: $g4;
				font-weight: 500;
				margin: 0.25vw;
			}
		}

		div.buttons {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 3.5vw;

			button {
				padding: 0.5vw;
				border: 0.1vw solid $g3;
				width: 40%;
				color: $g8;
				background: none;
				margin: 0vw 0.25vw;
				font-size: 0.75vw;
				cursor: pointer;

				&:hover {
					background: $o2;
					color: white;
				}

				&:nth-of-type(2) {
					background: $o1;
					color: white;
					&:hover {
						background: $o2;
						color: white;
					}
				}
			}
		}

	}
	.layers {
		height: 40vh;
		overflow: auto;
		overflow-x: hidden;
		margin-right: 7px;
		padding-left: 7px;
		margin-top: 3px;
		@include respond-to(1920px){
			//height: 21vh;
		}
		@include respond-to(1750px){
			//height: 23vh;
		}
		@include respond-to(1650px){
			height: 38vh;
		}
		@include respond-to(1550px){
			height: 42vh;
		}
		@include respond-to(1440px){
			height: 38vh;
		}
		div.selection {
			width: 80%;
		}
	}
	.absolute-save-bottom {
		position: absolute;
		bottom: 4.2vh;
		width: 10vw;
		text-align: center;
		left: 25%;
		.save-as{
			background: #f2f2f2;
			color: #979797;
			display: block;
			height: 5vh;
			line-height: 5.1vh;
			font-size: 1.1vw;
			transition: all 0.2s ease;
			&.main-button{
				border: none;
				i {
					padding-right: 5px;
				}
			}
			&:hover{
				background-color: $o1;
				color: white;
			}
		}

	}
	.frame {
		height: 180px;      /* equals max image height */
		white-space: nowrap;
		text-align: center; margin: 1em 0;
	}

	.helper {
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	.frame img {
		vertical-align: middle;
		max-height: 180px;
		max-width: 80%;
	}

}

.bottom-border{
	&.step2-border{
		margin-top: 0.4vw !important;
	}
}

/* Dropdown Button */
.menu ul {

	padding:0;

	margin:0;

	list-style-type:none;

	width: 100%;

}

.menu > ul {
	display: flex;
	> li {
		margin: 0 0px;
	}
}

.menu ul ul {
	width: 100%;

}

/* float the list to make it horizontal and a relative positon so that you can control the dropdown menu positon */

.menu li {

	flex: 1;

	text-align: center;

	position:relative;

}

.menu ul li ul li{

	background-color: white;
	flex: 1;

	text-align: center;

	position:relative;


}


.layers-list-header{
	margin-top: -14px;
}
/* style the links for the top level */

.menu a {

	display: block;
	width: 100%;
	height: 3.75vh;
	line-height: 4.05vh;
	border: 0.1vw solid #CBCBCB;
	background: none;
	font-size: 0.75vw;
	cursor: pointer;
	&:hover{
		text-decoration: none;
		background-color: $g1;
	}
	&.save-as{
		background: #56b5f5;
		color: white;
		&:hover{
			//background-color: $o2;
		}
	}

}

/* a hack so that IE5.5 faulty box model is corrected */

* html .menu a, * html .menu a:visited {

	width: 100%;

	w\idth: 100%;

}


/* style the second level hover */

.menu ul ul a.drop:hover {

	background: white;
	color: $g6;

}

.menu ul ul :hover > a.drop {

	background:white;
	color: $g6;

}


/* style the table so that it takes no part in the layout - required for IE to work */

.menu table {border-collapse:collapse; border:0; position:absolute; left:0; bottom:-1px;}



/* hide the sub levels and give them a positon absolute so that they take up no room */

.menu ul ul {

	visibility:hidden;

	position:absolute;

	bottom:31px;

	left:0;

	width: 100%;

}

* html .menu ul ul {

	bottom:30px;

}

/* position the third level flyout menu */

.menu ul ul ul{

	left:150px;

	bottom:0;

	width: 100%;

}

/* position the third level flyout menu for a left flyout */

.menu ul ul ul.left {

	left:-150px;

}





/* style the second level links */

* html .menu ul ul a{

	width:100%;

	w\idth:100%;

}





/* style the top level hover */

.menu ul ul a:hover{

	color: $g6;
	border: 1px solid $g6;
	background: white;

}

.menu ul ul :hover > a {

	color: $g6;

	background: white;

}



/* make the second level visible when hover on first level list OR link */

.menu ul li:hover ul,

.menu ul a:hover ul{

	visibility:visible;

	height:auto;

}

/* keep the third level hidden when you hover on first level list OR link */

.menu ul :hover ul ul{

	display:none;

}

/* keep the fourth level hidden when you hover on second level list OR link */

.menu ul :hover ul :hover ul ul{

	display:none;

}

/* make the third level visible when you hover over second level list OR link */

.menu ul :hover ul :hover ul{

	display:block;

	bottom:0;

}

/* make the fourth level visible when you hover over third level list OR link */

.menu ul :hover ul :hover ul :hover ul {

	display: block;

	bottom: 0;
}
div#view {
	div.popup.preloader{
		width: 100%;
		height: 100%;
		max-width: none;
		max-height: none;
		position: fixed;
		margin: 0;
		top: 0;
		left: 0;
		bottom: 0; 
		margin: auto;
		text-align: center;
		z-index: 12;
		div.popup-wrap {
			width: 100%;
			height: 100%;
			max-width: 400px;
    		max-height: 400px;
			img.preloader-logo{
				z-index: 50;
				border-radius: 20px;
				width: 250px;
				-webkit-font-smoothing: antialiased;
				opacity: 1;
				margin: 0 0 25px;
				animation: ld-spin 2.5s infinite;  
				//animation: infinite-spinning 4s linear infinite;
			}
			div.preloader-progress-bar {
				h4 {
					margin: 0 0 15px;
				}
			}
			div.overlay {
				background-color: $w1; 
			} 
		}
	}
}
.overlay{
	background: rgba(0,0,0,0.46);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: -1;
}

.beginDesigning{
	transition: all 0.2s ease;
	&:hover{
		background-color: $o2 !important;
	}
}

@-webkit-keyframes breathing {
	0% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	25% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	60% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	100% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
}

@keyframes breathing {
	0% {
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}

	25% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

	60% {
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}

	100% {
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}
}

.white-popup {
	position: relative;
	background: #FFF;
	padding: 20px;
	width: auto;
	max-width: 500px;
	margin: 20px auto;
}

.bottom-section ::-webkit-scrollbar {
	width: 8px;
}

/* Track */

::-webkit-scrollbar {
	width: 0px !important;
	background: transparent; /* make scrollbar transparent */
}

.bottom-section ::-webkit-scrollbar {
	width: 6px !important;
}

.bottom-section ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

/* Handle */
.bottom-section ::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: rgba(27,181,246,0.4);
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.bottom-section ::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0,0,0,0.2);
}

.view-dimensions-wrap{
	display: none;
	&.active{
		display: block;
	}
}


/*Font size box selector*/

			.fontsize-box {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				height: 2vw;
				span {
					font-size: 1vw;
					flex: 3;
					color: $g4;
					padding: 0.5vw 0.25vw;
					padding-left: 9px;
					&.m-right {
						margin-right: 1.5vw;
					}
				}

				div.quote-input {
					display: flex;
					flex-direction: row;
					align-items: center;
					position: relative;
					height: 100%;
					input {
						border: 0.1vw solid #CBCBCB;
    					border-radius: 0.2vw;
						margin-left: 0.25vw;
						padding-right: 1.6vw;
						text-align: right;
						width: 5.5vw;
						font-size: 1vw;
						font-weight: 500;
						color: $g4;
						height: 100%;
						//border: 0.1vw solid $g12 !important;
						outline: none;
						//border-radius: 0.15vw;
						border: 1px solid $g6;
						&:focus{
							border-color: $g6 !important;
						}
					}

					div.arrow-top {
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 0.4vw 0.4vw 0.4vw;
						border-color: transparent transparent $o1 transparent;
						position: absolute;
						left: 4.5vw;
						top: 0.45vw;
						cursor: pointer;
						&:hover {
							border-color: transparent transparent $o2 transparent;
						}
						&:active {
							border-color: transparent transparent $g3 transparent;
						}
					}

					div.arrow-bottom {
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0.4vw 0.4vw 0 0.4vw;
						border-color: $o1 transparent transparent transparent;
						position: absolute;
						left: 4.5vw;
						bottom: 0.45vw;
						cursor: pointer;
						&:hover {
							border-color: $o2 transparent transparent transparent;
						}
						&:active {
							border-color: $g3 transparent transparent transparent;
						}
					}

					p {
						margin-left: 0.5vw;
						color: $g4;
					}

				}

			}