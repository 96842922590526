form.styled-form {
    input {
        height: 3vw;
        padding: 1.5vw;
        color: #6c6c6c;
        border: solid 1.1px #dedede;
        border-radius: 5.6px;
        background-color: #f6f6f6;
        box-shadow: none;
        //Types
        &[type='text'],
        &[type='email'] {
            border-left: 0;
            height: 48.25px;
        }
        &[type='submit'] {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 1.2vw;
            font-weight: bold;

            height: auto;
            padding: 0.75vw;
            padding-right: 3.5vw;
            padding-left: 3.5vw;

            text-align: center;
            letter-spacing: 0.25vw;
            text-transform: uppercase;

            color: #fff;
            border: 0;
            border-radius: 0;
            background-color: #55b4f6;
            //Stattes
            &:hover {
                background-color: #2a6591;
            }
        }
    }
    textarea {
        height: 16vw;
        padding: 20px;

        border: solid 1.1px #dedede;
        background-color: #f6f6f6;
        box-shadow: none;
    }
    .input-group {
        margin-bottom: 1vw;
        .input-group-addon {
            border-top: solid 1.1px #dedede;
            border-bottom: solid 1.1px #dedede;
            border-left: solid 1.1px #dedede;
            border-radius: 5.6px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #f6f6f6;
            height: 48.25px;
            i {
                font-size: 1.25vw;
                color: #6c6c6c;
            }
        }
    }
    .form-control::-webkit-input-placeholder,
    .form-control:-moz-placeholder,
    .form-control::-ms-input-placeholder {
        font-size: 18px;
        color: #6c6c6c;
    }
    span {
        //Modifiers
        &.success {
            color: #27ae60;
        }
        &.error {
            color: #c0392b;
        }
        &.success,
        &.error {
            margin-left: 15px;
            display: none;
            //States
            &.is-active {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}