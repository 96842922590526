div#signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  div.signin-wrap {
    padding: 1vw;
    padding-bottom: 10vw;
    width: 100%;
    max-width: 500px;
    text-align: center;



    div {
      a.btn {
        background-color: $o2;
        color: $w1; 
        margin: 0 10px;
      }
    }
    img {
      display: block;
      margin: 2vw auto;
      width: 15vw;
      @include media('<=phone') {
        margin: 6.25vw auto;
        width: 48vw;
      }
    }
    p.center {
      text-align: center;
      color: $o2;
      margin-top: 2vw;
      @include media('<=phone') {
        margin-top: 6.51vw;
      }
    }
    div.underline {
      border-bottom: 0.1vw solid $g3;
      padding-top: 0.75vw;
      margin-bottom: 1.5vw;
      width: 100%;
    }
    input {
      display: block;
      color: $o1;
      border: none;
      border: 0.1vw solid $g3 !important;
      width: 100%;
      padding: 0.5vw;
      margin-bottom: 1.5vw;
      font-size: 1vw;
      text-align: center;
      border-radius: 0.25vw;

      @include media('<=phone') {
        font-size: 3.25vw;
        border-radius: 0.75vw;
        margin-bottom: 4.88vw;
        border: 0.32vw solid $g3 !important;
      }
    }
    .blue-button {
      display: block;
      width: 100%;
      padding: 0.5vw;
      font-size: 1.5vw;
      color: white;
      background: $o2;
      border: none;
      border-radius: 0.25vw;
      box-shadow: 0 0 0.5vw $g3;
      -webkit-animation-delay: .45s;
      animation-delay: .45s;
      background-color: #55b4f6;
      @include media('<=tablet') {
        font-size: 1.52vw;
        border-radius: 0.35vw;
        margin-bottom: 2.28vw;
        box-shadow: 0 0 0.75vw $g3;
        padding: 0.75vw;
        height: 3.32vw;
      }
      @include media('<=phone') {
        font-size: 3.25vw;
        border-radius: 0.75vw;
        margin-bottom: 4.88vw;
        box-shadow: 0 0 1.62vw $g3;
        padding: 1.62vw;
        height: 7vw;
      }
      &:hover {

        background-color: #2a6696;
      }
    }
    .grey-button {

      display: block;
      width: 100%;
      padding: 0.5vw;
      font-size: 1.5vw;
      color: black;
      background: $inactive_grey;
      border: none;
      border-radius: 0.25vw;
      -webkit-animation-delay: .45s;
      animation-delay: .45s;
      @include media('<=phone') {
        font-size: 3.25vw;
        border-radius: 0.75vw;
        margin-bottom: 4.88vw;
        box-shadow: none;
        padding: 1.62vw;
        height: 34px;
      }
      &:hover {
        text-decoration: none;
        background-color: #2a6696;
        color: white;
      }
    }

    a.lite {
      text-decoration: none;
      button {
        background: $g2;
        color: black;
      }
    }
  }
}


