.images-library{
    list-style-type: none;
    height: 15vw;
    overflow: auto;
    padding: 0;
    margin: 0 1.4vw;
    @include respond-to(1920px){
        height: 21vh;
    }
    @include respond-to(1750px){
        height: 23vh;
    }
    @include respond-to(1650px){
        height: 25vh;
    }
    @include respond-to(1550px){
        height: 27vh;
    }
    @include respond-to(1440px){
        height: 35vh;
    }

    li {
        transition: all 0.2s ease;
        border: 2px solid transparent;
        padding: 5px;
        margin: 0.6vw;
        &:hover{
            border: 2px solid $o1;
            cursor: pointer;
        }
        img.placeholder {
            margin: 0 auto;
        }
    }
}