.blog,
.blog-single
{
  .container-fixed {
    width: 90%;
    max-width: 800px;
  }
  .container-fixed.is-small {
    max-width: 80%;
  }

  h1 {
    color: #004683;
    margin: 4.20vw 0 2.09vw;
    font-size: 4.23vw;
    @include media('<=tablet') {
      font-size: 5.25vw !important;
    }
    @include media('<=phone') {
      font-size: 6.5vw;
    }
    text-align: center;
  }

  a.back {
    color: #34a4f5;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.88vw !important;
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: block;

    @include media('<=tablet') {
      font-size: 1.8vw !important;
    }
    @include media('<=phone') {
      font-size: 2.5vw !important;
    }
  }

  article {
    margin-bottom: 3.5vw;
    h2, h3
    {
      margin: 3.72vh 0 3.72vh;
      color: #004683;
      a {
        color: #004683;
        margin: 0 0 25px;
        font-size: 2.5vw;
        letter-spacing: 0.5px;
        cursor: pointer;
        text-decoration: none;
        @include media('<=tablet') {
          font-size: 3.35vw;
        }
        @include media('<=phone') {
          font-size: 4.5vw;
        }
      }
    }
    .date {
      display: block;
      text-align: left;
      color: #34a4f5;
      padding: 0;
      font-size: 1.02vw !important;
      font-weight: 100;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin: 25px 0 25px;
      line-height: 1.62;
      @include media('<=tablet') {
        font-size: 1.80vw !important;
      }
      @include media('<=phone') {
        font-size: 2.5vw !important;
      }
    }
    div.summary p, p{
      font-size: 1.1vw;
      font-weight: 100;
      width: 100%;
      line-height: 1.85;
      @include media('<=tablet') {
        font-size: 2.2vw;
      }
      @include media('<=phone') {
        font-size: 3.5vw;
      }
    }
    a.read-more {
      color: #34a4f5;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.88vw !important;
      font-weight: 700;
      letter-spacing: 2px;
      cursor: pointer;
      @include media('<=tablet') {
        font-size: 1.8vw !important;
      }
      @include media('<=phone') {
        font-size: 2.5vw !important;
      }
    }
  }
}