div#boxes-cards-container {
    height: auto;
    width: 76.47vw;
}
div.services-section__first,
div.services-section__second,
div.services-section__third { 
    height: 100%;
    a {
        padding: 2.5vw 1.5vw 2.5vw!important;
        width: 100%;
        div.box-img {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 15.62vw;
            margin: 0 auto 14px;
            width: 22vw;
            //Modifiers
            &.mailer {
                background-image: url('/images/home/Box_Selection_-_Mailer_Closed_(Initial_State).png');
                background-size: 14.39vw;
                background-position: 2vw 3vw;
                //Preload
                &:after {
                    content: "";
                    height: 0;
                    width: 0;
                    visibility: hidden;
                    overflow: hidden;
                    background: url('/images/home/Box_Selecton_-_Mailer_Open_(Hover_State).jpg');

                }
            }
            &.rsc {
                background-image: url('/images/home/Box_Selection_-_RSC_Closed_(Initial_State).jpg');
                background-position: 3.1vw 2vw;
                background-size: 15.62vw;
                //Preload
                &:after {
                    content: "";
                    height: 0;
                    width: 0;
                    visibility: hidden;
                    overflow: hidden;
                    background: url('/images/home/Box_Selection_-_RSC_Open_(Hover_State).jpg');

                }
            } 
            &.carton {
                background-image: url('/images/home/Box_Selection_-_Carton_Closed_(Initial_State).jpg');
                background-position: 4.3vw 1vw;
                background-size: 12.99vw;
                //Preload
                &:after {
                    content: "";
                    height: 0;
                    width: 0;
                    visibility: hidden;
                    overflow: hidden;
                    background: url('/images/home/Box_Selection_-_Carton_Open_(Hover_State).jpg');

                }
            }
        }
    }
    //States 
    &:hover {
        a {
            div.box-img {
                &.mailer {
                    background-image: url('/images/home/Box_Selecton_-_Mailer_Open_(Hover_State).jpg');
                    background-position: 1.6vw 3.2vw;
                    background-size: 18.55vw;
                }
                &.rsc {
                    background-image: url('/images/home/Box_Selection_-_RSC_Open_(Hover_State).jpg');
                    background-position: 0.6vw 1vw;
                    background-size: 18.55vw;
                }
                &.carton {
                    background-image: url('/images/home/Box_Selection_-_Carton_Open_(Hover_State).jpg');
                    background-size: 15.95vw;
                    background-position: 3.5vw 0vw;
                }
            }
        }
    }
    //Responsive
    @media (max-width: 1920px) {
        a {
            div.box-img {
                &.mailer {
                    background-position: 4.5vw 4vw;
                    //background-size: 235px;
                }
                &.rsc {
                    background-position: 5vw 2.6vw;
                }
                &.carton {
                    background-position: 6.8vw 1.5vw;
                }
            }
        }
        //States
        &:hover {
            a {
                div.box-img {
                    &.mailer {
                        background-position: 3.6vw 4.3vw;
                        //background-size: 1.8vw 1.3vw
                        background-size: 14.9vw;
                    }
                    &.rsc {
                        background-position: 1.9vw 1.3vw;
                        //background-size: 1.8vw 1.3vw
                    }
                    &.carton {
                        background-position: 5.8vw 0.2vw;
                        background-size: 15.8vw;
                    }
                }
            }
        }
    }
    @media (max-width: 1280px) { 
        a {
            div.box-img {
                &.rsc {
                    background-position: 5.2vw 2.6vw;
                }
            }
        }
        //States
        &:hover {
            a {
                div.box-img {
                    &.mailer {
                        background-size: 14.39vw;
                        background-position: 3.4vw 3vw;
                    }
                    &.rsc {
                        //background-position: 1.3vw 1.1vw;
                        background-position: 1.8vw 1.3vw;
                    }
                    &.carton {
                        background-position: 5.8vw 0vw;
                    }
                }
            }
        }
    }
} 

//services-section
div.services-section {
    margin-bottom: 6vh;
    & > div {
        div {
            flex: 1; 
            a {
                height: 100%;
            }
        }
    }
}

@include media('<=phone') {
    div.box-img {
        &.mailer {
            background-position: 9.33vw center;
            background-size: 55vw;
            width: 64vw;
            height: 61vw;
        }
    }
}