@font-face {
  font-family: 'cbb';
  src:  url('/fonts/vendor/cbb/cbb.eot?wge681');
  src:  url('/fonts/vendor/cbb/cbb.eot?wge681#iefix') format('embedded-opentype'),
        url('/fonts/vendor/cbb/cbb.ttf?wge681') format('truetype'),
        url('/fonts/vendor/cbb/cbb.woff?wge681') format('woff'),
        url('/fonts/vendor/cbb/cbb.svg?wge681#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="cbb-"], [class*=" cbb-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cbb' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cbb-caret-down:before {
  content: "\e900";
}
.cbb-caret-up:before {
  content: "\e901";
}
.cbb-cart:before {
  content: "\e902";
}
.cbb-close:before {
  content: "\e903";
}
.cbb-delete:before {
  content: "\e904";
}
.cbb-edit:before {
  content: "\e905";
}
.cbb-menu:before {
  content: "\e906";
}
.cbb-lock:before {
  content: "\e907";
}
.cbb-search:before {
  content: "\e908";
}
