body:after{
    display:none;
    content:
    url('/img/bottom_bar/save-icon-hover.svg')
    url('/img/bottom_bar/restart-icon-hover.svg')
    url('/img/panels/box-bottom-active.svg')
    url('/img/panels/box-top-active.svg')
    url('/img/panels/box-front-active.svg')
    url('/img/panels/box-back-active.svg')
    url('/img/panels/box-left-active.svg')
    url('/img/panels/box-right-active.svg')
    url('/img/panels/box-bottom-hover.svg')
    url('/img/panels/box-top-hover.svg')
    url('/img/panels/box-front-hover.svg')
    url('/img/panels/box-back-hover.svg')
    url('/img/panels/box-left-hover.svg')
    url('/img/panels/box-right-hover.svg')
    url('/img/bottom_bar/view-2d-active.png')
    url('/img/bottom_bar/view-3d-active.png')
    url('/img/bottom_bar/view-2d-hover.png')
    url('/img/bottom_bar/view-3d-hover.png')

    url('/img/bottom_bar/icon-undo-hover.svg')
    url('/img/bottom_bar/icon-redo-hover.svg')

    ;
}

div.step {
  display: flex;
  flex-direction: row;

  div.button {
    border: 1px solid $g4;
    background: white;
    border-radius: 5px;
    padding: 0.1vw;
    margin: 0vw 1.5vw;
    text-align: center;
    box-shadow: 4px 4px 4px $g3;
    overflow: hidden;
    cursor: pointer;
    &.active {
      border: 2px solid $g4;
    }

    &.hoverOrange {
      &:hover {
        color: $o1;
      }
      &:active {
        color: $g4;
      }
    }

    p {
      font-size: 0.9vw;
      margin-bottom: 0.1vw;
    }

    img {
      display: block;
      width: 75%;
      margin: auto;
    }

    &.lessmargin {
      margin: 0vw 0.1vw;
      border: none;
      box-shadow: none;
      margin-bottom: -0.25vw;
      overflow: hidden;
    }

    &.three {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0vw 1.25vw;
      height: 4vw;
      img {
        width: 70%;
      }
    }

    &.lesspadding {
      height: 4vw !important;
    }

    &.six {
      width: 16.6%;
      p {
        margin-bottom: 0vw;
      }
      img {
        width: 90%;
      }
    }

    &:first-of-type {
      margin-left: -0.5vw;
    }
    &:last-of-type {
      margin-right: -0.5vw;
    }

    &.options {
      margin-bottom: 0.5vw;
      height: 5.5vw;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: auto !important;
        height: 100% !important;
        margin: 0 !important;
      }
      p {
        display: none;
      }
      &:nth-of-type(odd) {
        margin-left: 0vw;
        margin-right: 0.05vw;
      }
      &:nth-of-type(even) {
        margin-right: 0vw;
        margin-right: 0.05vw;
      }
      &:nth-of-type(2) {
        img {
          height: 80% !important;
        }
      }

      &:nth-of-type(3) {
        img {
          height: 80% !important;
        }
      }
      &:nth-of-type(3) {
        margin-bottom: 0vw;
      }
      &:nth-of-type(4) {
        margin-bottom: 0vw;
      }
    }

    &.two {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 40%;
        margin-bottom: 0.25vw;
      }
    }

  }

  div.menu-wrap {

    display: flex;
    flex-direction: row;

    padding: 0.75vw 1vw;

    div.menu-column {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid $g4;
      border-radius: 5px;

      &:nth-child(1) {
        width: calc(25% - 1vw);
        margin-right: 1vw;
      }

      &:nth-child(2) {
        width: 50%;

        div.button {
          p {
            font-size: 1vw !important;
            padding-bottom: 0.5vw;
          }
        }

      }

      &:nth-child(3) {
        width: calc(25% - 1vw);
        margin-left: 1vw;
        div.button {
          img {
            width: 45%;
            margin-top: 0.75vw;
          }
          p {
            font-size: 1vw !important;
            padding-bottom: 0.5vw;
          }
        }
      }

      div.line {
        height: 70%;
        border-right: 1px solid $g4;
        &.invisible {
          opacity: 0;
        }
      }

      div.button {
        border: none;
        width: 50%;
        margin: 0px;
        border-radius: none;
        box-shadow: none;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 5.5vw;
        p {
          font-size: 1.5vw;
        }
        &.active {
          color: $o1;
        }
        img {
          width: 30%;
        }
      }

    }

  }

  div.column {
    padding: 0vw 1.5vw 1vw 1.5vw;
    width: 33.33vw;

    div.step-heading {
      display: flex;
      align-items: center;
      margin: 2vw 1vw 2vw 2vw;
      cursor: default;
      img {
        width: 3vw;
        height: 3vw;
        box-shadow: 0px 2.5px 5px $g3;
        border-radius: 50%;
      }
      span.step-heading-text {
        font-size: 2.25vw;
        margin-left: 1vw;
      }
      &.smaller {
        width: 100%;
        margin-top: 0.5vw;
        margin-bottom: 0.5vw;
        margin-left: 0;
        margin-right: 0;
        justify-content: center;
        img {
          width: 1.75vw;
          height: 1.75vw;
        }
        span.step-heading-text {
          font-size: 1.5vw;
          text-transform: uppercase;
        }
        &.nomargin {
          margin-top: 0.5vw;
        }
      }
    }

    div.active-box {
      display: block;
      padding-top: 15vw;
      margin: auto;
      width: 27.5vw;
      img {
        width: 100%;
        margin: 0px;
        &.fol {
          width: 75%;
          display: block;
          margin: auto;
          position: relative;
          top: -4vw;
        }
        &.fivepanel {
          width: 85%;
          display: block;
          margin: auto;
          position: relative;
          top: -2vw;
        }
        &.mailer {
          width: 95%;
          display: block;
          margin: auto;
          position: relative;
          top: -3vw;
        }
      }
    }

    div.heading {
      text-transform: capitalize;
      font-size: 2.25vw;
      text-align: center;
      margin: 0vw 0vw 0.5vw 0vw;
      span:nth-of-type(2) {
        text-transform: uppercase;
      }
    }

    a.continue-button {
      background: $o1;
      color: white;
      text-decoration: none;
      padding: 1vw;
      border-radius: 0.5vw;
      font-size: 1.5vw;
      display: block;
      width: calc(100% - 2.5vw);
      text-align: center;
      margin: 2.5vw 1vw;
      position: relative;
      left: 2vw;
      box-shadow: 5px 5px 1vw $g3;
    }

    div.select-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      z-index: 0;
      margin: 0px 15px;
      z-index: 1;

      &.material {
        z-index: 2;
      }

      div.header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 3.5vw;
        border: 1px solid $g4;
        border-radius: 5px 5px 5px 5px;
        font-size: 1.75vw;
        font-weight: 300;
        position: relative;
        z-index: 0;
        p {
          margin-left: 1vw;
          width: 75%;
        }
        i.fa {
          color: $o1;
          font-size: 2.5vw;
          margin-right: 1.5vw;
        }
        div.line {
          border-right: 1px solid $g4;
          height: 70%;
          position: relative;
          left: -1vw;
        }
      }
      div.body {
        border: 1px solid $g4;
        border-top: none;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 0px 0px 15px $g3;
        background: white;
        position: absolute;
        z-index: 100;
        top: 3.5vw;
        left: 0px;
        width: 100%;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        div.option {
          border-bottom: 1px solid $g4;
          padding: 10px;
          &:hover {
            background: $g1;
          }
          &:last-of-type {
            border-bottom: none;
          }
        }
      }

      &.active {
        div.body {
          opacity: 1;
          pointer-events: visible;
        }
      }

    }

  }

  &.step2 {
    div.column {
      width: 20.5vw;

      &:nth-of-type(2) {
        width: 61vw;
      }

      &:nth-of-type(3) {
        width: 19.5vw;
        //right: 2.3vw;
        //bottom: 4.3vh;
        padding: 0 2.3vw 4.3vw 0;
        box-sizing: border-box; 
        
        div.pricing.prices-box {
          transform: translate(-2.3vw, -2.3vw);
        }
        div#checkout-box {
          transform: translate(-2.3vw, -2.3vw);
        }
      }

    }
  }

}
