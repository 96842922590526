div.popup {
    background: rgba(0,0,0,0.5);
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    pointer-events: none;

    &.visible {
        opacity: 1;
        pointer-events: visible;
    }

    div.popup-wrap {
        position: relative;
        background: white;
        padding: 1vw;
        border-radius: 0.15vw;

        div.save-error {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;
            flex-direction: column;
            justify-content: center;
            background-color: $w1;
            font-size: 18px;
            color: $r1;
            //Modifiers
            &.is-active {
                display: flex;
            }
        }

        button.close-button {
            position: absolute;
            top: -20px;
            right: -20px;
            border: none;
            height: 40px;
            width: 40px;
            border-radius: 40px;
            background-color: $o2;
            color: $g1;
            font-size: 30px;
            line-height: 18px;
            z-index: 10;
        }
        div.buttons {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            button {
                background: $o1;
                border: 0.1vw solid $o1;
                border-radius: 0.15vw;
                padding: 0.5vw;
                color: white;
                font-weight: 500;
                font-size: 1vw;
                margin: 1vw 0.5vw 0.25vw 0.5vw;
                &:first-of-type {
                    background: white;
                    color: $g4;
                    border: 0.1vw solid $g4;
                }
            }
        }
    }
    //Modifiers
    &.preloader {
        background: transparent;
        opacity: 1;  
        div.popup-wrap {
            background-color: transparent; 
            p {
                display: none; 
            }
        }
    }
}

div.undo-warp{
    position: absolute;
    top: 4.4vw;
    right: 22vw;
    div.undoredo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 6.5vw;

        display: none;
        position: relative;
        &.active{
            display: flex;
        }
        img {
            padding: 0.09vw;
            width: 2.7vw;
        }
        &.threeD{
            img {
                width: 3.2vw;
            }
        }
        p {
            font-size: 1vw;
            text-align: center;
            margin-bottom: 0.2vw;
            color: $g8;
        }
        .undoredo-icons { 
            width: 50%;
            float: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                max-width: none;
                will-change: auto;
                transition: all 0.3s ease;
                width: 2.5vw;
                height: 2.5vw;
            }
            //Modifiers
            &.big-icons {
                a {
                    img:first-of-type {
                        width: 3.3vw;
                        height: 3vw;
                    }
                }
                img:first-of-type {
                    width: 3.3vw;
                    height: 3vw;
                }
                img:last-of-type {
                    width: 3.1vw;
                    height: 3.1vw;
                }
                //Childs
                &:first-of-type {
                    p {
                        position: relative;
                        top: 1px;
                    }
                    img {
                        width: 3.6vw; 
                        height: 3.6vw;
                    }
                }
                &:last-of-type {
                    p {
                        position: relative;
                        top: -3px;
                    }
                }
            } 
        }
        cursor: pointer;
    }
}

div.login-popup {
    padding: 1vw;
    div.popup-wrap {
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 370px;
        flex: 1;
        padding: 2vh 2vw;
        div.popup-inner-container {
            div.signin-wrap {
                text-align: center;
                padding-top: 4vh;
                & > img {
                    width: 100%;
                    max-width: 250px;
                    margin: 0 0 4vh;
                }
                form {
                    div.btns-group {
                        button {
                            margin-right: 15px;
                        }
                    }
                    div.form-group {
                        span.help-block {
                            color: $r1;
                            strong {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }   
}

div.save-design-popup {
    div.popup-wrap {
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 280px;
        flex: 1;
        padding: 5vh 1vw;
        text-align: center;
        div.popup-inner-container {
            h2 {
                margin: 0 0 2vh;
                display: none;
            }
            p.label {
                color: $g4;
                font-size: 1vw;
                margin: 0 0 2vh;
                display: block;
            }
            input[type="text"] {
                display: block;
                margin: 0 auto;
                width: 100%;
                max-width: 350px;
                border: none;
                padding: 5px;
                border-radius: 2px;
                background-color: $g3;
                margin: 0 auto 4vh;
            }
            div.buttons {
                button {
                    background-color: $o1;
                    color: $w1;
                    border: none;
                    width: 100%;
                    max-width: 200px;
                    //Childs
                    &:first-of-type {
                        background-color: $o1;
                        color: $w1;
                        border: none;
                        width: 100%;
                        max-width: 200px;
                    }
                }
            }
            //Modifiers
            &.overwrite-design {
                display: none;
            }
        }
    }
    //Modifiers
    &.is-existing-design {
        div.popup-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;
            max-height: 335px;
            max-width: 960px;
            div.popup-inner-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
                h2 {
                    display: block;
                }
                //Childs
                &:first-of-type {
                    border-right: 2px solid $g4;
                }
                //Modifiers
                &.overwrite-design {
                    display: flex;
                }
            }
        }
    }
}

div.contact-us-popup {
    div.popup-wrap {
        width: 100%;
        max-width: 720px;
        padding: 30px 15px 0;
    }
}