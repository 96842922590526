div#account {

	div.account-wrapper {

		h1 {
			text-align: center;
			margin: 15px 0px;
		}

		div.tabs {
			box-shadow: 0px 0px 15px $g3;
			div.tabs-wrapper {
				width: 768px;
				margin: auto;
				display: flex;
				flex-direction: row;
			}
			div.tab {
				margin: 15px 0px;
				padding: 15px;
				width: 33.33%;
				font-size: 18px;
				text-align: center;
				border: 0.1vw solid white;

				background: $o1;
				color: white;

				&.active {
					background: $o2;
				}
			}
		}

		div.sub-tabs {
			display: flex;
			flex-direction: row;

			div.sub-tab {
				border: 0.2vw solid white;
				background: $o1;
				color: white;
				padding: 10px;
				font-size: 18px;
				&.active {
					background: $o2;
				}
			}

		}

		div.my-account {
			width: 100%;
			max-width: 768px;
			margin: 15px auto;
			h2, h3 {
				font-weight: 400;
			}
			h2 {
				margin-bottom: 15px;
			}
			h3 {
				font-weight: 300;
				line-height: 2;
			}
			input {
				width: 100%;
				max-width: 300px;
				margin-bottom: 15px;
				padding: 0px;
				font-size: 14px;
				display: block;
				&.no-margin {
					margin-bottom: 0px;
				}
			}

			div.special-input {
				display: flex;
				flex-direction: row;
				input {
					margin-right: 15px;
				}
			}

			button {
				width: 100%;
				max-width: 150px;
				border: none;
				background: $o2;
				color: white;
				font-size: 18px;
				padding: 5px;
				border-radius: 5px;
			}

			div.button-wrap {
				display: flex;
				flex-direction: row;
				width: 100%;
				max-width: 300px;
			}
		}

	}

}
