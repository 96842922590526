div.zsiq_floatmain.zsiq_theme1.siq_bR {
    bottom: 35px;
    right: 35px;
    div.zsiq_float {
        div.zsiq_flt_rel {
            div.zsiq_cnt {
                left: -165px;
                width: 150px;
            }
        }
    }
}