div#index {
  div.homepage-wrap {
    width: 100vw;
    position: relative;
    padding-bottom: 8vw;
    h1, h2 {
      font-weight: 300;
      text-align: center;
      margin: 0px 0px 1.5vw 0px;
    }
    h1 {
      margin-top: 3.5vw;
      margin-bottom: 5.5vw;
      font-size: 2.5vw;
    }
    h2 {
      font-size: 2vw;
      line-height: 1.5;
      position: relative;
      margin-bottom: 5.5vw;
    }
    img.bigboxes {
      position: absolute;
      left: 0px;
      top: 5.5vw;
      width: 40vw;
    }
    img.bigboxes2 {
      position: absolute;
      right: 0px;
      top: 5.5vw;
      width: 40vw;
    }
    div.branding-wrap {
      height: auto;
      padding-top: 30px;
    }
    a.start-building {
      display: block;
      margin: auto;
      width: 15vw;
      text-align: center;
      border-radius: 5px;
      background: $o1;
      color: white;
      text-decoration: none;
      padding: 15px;
      font-weight: 300;
      font-size: 2vw;
      box-shadow: 2.5px 2.5px 5px $g3;
      margin-top: 0px;
    }
    div.steps {
      opacity: 0;
      display: block;
      margin: auto;
      left: calc(50% - 320px);
      width: 640px;
      height: 100px;
      display: flex;
      flex-direction: row;
      color: $g4;
      border-radius: 5px;
      margin-bottom: 20px;
      div.step {
        width: 33.33%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
          display: block;
          margin: 0px auto;
        }
        h1 {
          margin: 0px;
          font-size: 18px;
        }
      }
    }
  }
}
