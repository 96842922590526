div.privacy-policy {
    padding: 50px 15px 0;
    width: 100%;
    max-width: 960px;
    margin: 0 auto 50px;
    text-align: justify;
    h1,
    p,
    ul,
    li {
        color: $g4;
    }
    a {
        color: $o2;
    }
}