footer.cbb-footer {
    background-color: $footer-bg;  
    text-align: center;
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    div.flex-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        ul {
            flex: 1;
            list-style: none;
            padding: 0;
            margin: 0;
            //Modifiers
            &.social {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;  
                li {
                    margin: 0 5px;
                    a {
                        display: block;
                        img {
                            display: block;
                            width: 100%;
                            max-width: 30px;
                        }
                    }
                    //Modifiers
                    &.link-facebook {

                    }
                    &.link-twitter {
                        
                    }
                    &.link-instagram {
                        a {
                            img {
                                max-width: 25px;
                            }
                        }                        
                    }
                    &.link-linkedin {
                        margin-right: 0;
                    }
                }
            }
            &.navigation {
                flex: 3;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                li {
                    margin: 0 10px;
                    a {
                        color: $footer-color;
                        font-family: 'Source Sans Pro', sans-serif;
                    }
                }
            }
        }
        div.copyright {
            flex: 2;
            color: $footer-color;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 12px;
            text-align: left;
            padding-left: 25px;
        }
    }
    //Responsive
    @media (max-width: 768px) {
        div.flex-wrapper {
            display: block;
            ul {
                //Modifiers
                &.navigation {
                    display: block;
                    margin: 0 0 15px;
                    li {
                        margin: 10px 0;
                    }
                }
                &.social {
                    max-width: 450px;
                    margin: 0 auto;
                    justify-content: center;
                }
            }
            div.copyright {
                padding: 0;
                text-align: center;
                margin: 0 0 25px;
            }  
        } 
    }
} 