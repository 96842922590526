//Builder Header
div#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px 5px 1.4vw;
    height: 3vw;
    max-height: 120px;
    border-bottom: 1px solid $g3;
    position: relative;
    div.image-wrap {
        a {
            display: block;
            img {
                display: block;
                height: 2vw;
                margin: 0 auto;
            }
        } 
        //Responsive
        @media (max-height: 800px) {
            a {
                img {
                    height: 2.014vw;
                }
            }
        } 
    } 
    div.signup {
        width: 100%;
        ul.user-menu {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            li {
                margin: 0 0 0 1vw;
                //Modifiers
                &#nav-username {
                    font-size: 18px;
                    color: $textBlue;
                    font-weight: 400;
                }
                &.sub-menu {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 20px;
                        height: 50px;
                        left: -10px;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        width: 100px;
                        height: 20px;
                        right: 0;
                        bottom: -5px;
                    }

                    a#user-menu-trigger {
                        -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
                        box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
                        height: 30px;
                        padding: 0 5px 0 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-size: 19px;
                        transition: none;
                        color: $textBlue;
                    }
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        position: absolute;
                        z-index: 2;
                        top: 95%;
                        right: 0;
                        width: 100%;
                        min-width: 198px;
                        -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
                        box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.2);
                        height: 0;
                        overflow: hidden;
                        background-color: #fff;
                        li {
                            background-color: $w1;
                            text-align: left;
                            padding: 3px 0;
                            a {
                                display: block;
                                width: 100%;
                                font-size: 18px;
                                color: $textBlack;
                                text-decoration: none;
                                padding: 3px 15px;
                            }
                            //States
                            &:hover {
                                background-color: $itemHoverBlue;
                                cursor: pointer;
                                a {
                                    color: $w1;
                                }
                            }
                        }

                    }
                    //States
                    &:hover {
                        padding-top: 10px;
                        a#user-menu-trigger {
                            padding: 0 5px 10px 5px;
                            box-sizing: content-box;
                        }
                        ul {
                            height: auto;
                        }
                    }
                }
                &.cart-button {
                    a {
                        font-size: 19px;
                        i {
                            color: $textBlue;
                        }
                    }
                }
            }
        }
        
        div#login-button-container {
            text-align: right;
            a {
                border: 1px solid #9fa3a7;
                color: #9fa3a7;
                text-decoration: none;
                padding: 6px 12px;
                font-size: 12px;
                font-weight: 400;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
                //States
                &:hover {
                    color: $w1;
                    border-color: $itemHoverBlue;
                    background-color: $itemHoverBlue;
                    outline: 0;
                }
            }
        }
    }
}

//Landing Header
nav.navbar,
div.navbar {
    position: relative;
    z-index: 1000;
    div#navbar {
        ul.nav.navbar-nav {
            li {
                padding: 0;
                margin-top: 2vw;
                margin-right: 4vw;
                //Modifiers
                &.has-submenu {
                    margin: 0;
                    ul.user-menu {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        li {
                            //Modifiers
                            &#nav-username {
                                font-size: 19px;
                                color: $textSubMenu;
                                font-weight: 400;
                                margin-right: 2vw;
                            }
                            &.sub-menu {
                                position: relative;
                                margin-right: 0;
                                a#user-menu-trigger {
                                    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
                                    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
                                    height: 30px;
                                    padding: 0 2px 0 5px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    font-size: 19px;
                                    transition: none;
                                    color: $textSubMenu;
                                    position: relative; 
                                    //Left surface to avoid loss of hover
                                    &:before {
                                        content: "";
                                        display: block;
                                        opacity: 0;
                                        width: 20px;
                                        height: 100%;
                                        position: absolute;
                                        top: 0;
                                        left: -20px;
                                    }
                                }
                                ul {
                                    list-style: none;
                                    margin: 0;
                                    padding: 0;
                                    position: absolute;
                                    z-index: 2;
                                    top: 100%;
                                    right: 0;
                                    width: 100%;
                                    min-width: 198px;
                                    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
                                    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.2);
                                    height: 0;
                                    overflow: hidden;
                                    li {
                                        background-color: $w1;
                                        text-align: left;
                                        padding: 3px 0;
                                        margin: 0;
                                        a {
                                            font-size: 1.4rem;
                                            color: $textBlack;
                                            text-decoration: none;
                                            padding: 3px 15px;
                                            color: $textSubMenu;
                                        }
                                        //States
                                        &:hover {
                                            background-color: $itemHoverBlue;
                                            a {
                                                color: $w1;
                                            }
                                        }
                                    }
            
                                }
                                //States
                                &:hover {
                                    a#user-menu-trigger {
                                        box-sizing: content-box;
                                    }
                                    ul {
                                        height: auto;
                                    }
                                }
                            }
                            &.cart-button {
                                margin-right: 2vw;
                                a {
                                    font-size: 19px;
                                    i {
                                        color: $textSubMenu;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //Modifiers

    &.is-about {
        height: auto;
        min-height: 745.61px;
        padding-top: 3vw;
        //background
        //background-image: url('/assets/images/about-us/Grey_BG_01.png');
        //background-position: center;
        //background-repeat: no-repeat;
        //background-size: cover;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f9fb+55,dad9dd+100 */
        background: #f6f9fb; /* Old browsers */
        background: -moz-linear-gradient(45deg, #f6f9fb 55%, #dad9dd 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #f6f9fb 55%,#dad9dd 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #f6f9fb 55%,#dad9dd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f9fb', endColorstr='#dad9dd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        div.container-fixed {
            div.navbar-header {
                a.navbar-brand {
                    padding-left: 3.32vw !important;
                    img {
                        min-width: 20vw;
                    }
                }
            }
            div#navbar {
                .navbar
                ul.navbar-nav {
                    padding-right: 0;
                    li {
                        margin-top: 1.5vw;
                        a {
                            color: $about-us-header-title;
                            //States
                            &:hover {
                                color: $textSubMenu;
                            }
                        }
                        //Modifiers
                        &.has-submenu {
                            margin-top: 0;
                            ul.user-menu {
                                li {
                                    //Modifiers
                                    &#nav-username,
                                    &.cart-button,
                                    &.sub-menu {
                                        color: $about-us-header-title;
                                        a {
                                            color: $about-us-header-title;
                                            i {
                                                color: $about-us-header-title;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.login {
                            a {
                                border-color:  $about-us-header-title;
                                //States
                                &:hover {
                                    border-color: $textSubMenu;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}