//Variables
$max-width: 1400px;
$max-width-small: 1240px;

//Mixins
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.container-fixed {
    @include clearfix;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding: 15px;
    //Modifiers
    &.is-small {
        max-width: $max-width-small;
    }
}

.flex-container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    div {
        flex: 1;
        //Modifiers
        &.has-ov-hidden {
            overflow-x: hidden;
        }
    } 
}

