
.picker-wrapper,
.slide-wrapper {
    position: relative;
    float: left;
}
.picker-indicator,
.slide-indicator {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}
.picker,
.slide {
    cursor: crosshair;
    float: left;
}

/* Default skin */

.cp-default {
    background-color: gray;
    padding: 12px;
    box-shadow: 0 0 40px #000;
    border-radius: 15px;
    float: left;
}
.cp-default .picker {
    width: 200px;
    height: 200px;
}
.cp-default .slide {
    width: 30px;
    height: 200px;
}
.cp-default .slide-wrapper {
    margin-left: 10px;
}
.cp-default .picker-indicator {
    width: 5px;
    height: 5px;
    border: 2px solid darkblue;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    opacity: .5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    filter: alpha(opacity=50);
    background-color: white;
}
.cp-default .slide-indicator {
    width: 100%;
    height: 10px;
    left: -4px;
    opacity: .6;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    filter: alpha(opacity=60);
    border: 4px solid lightblue;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: white;
}

/* Small skin */

.cp-small {
    padding: 5px;
    background-color: white;
    float: left;
    border-radius: 5px;
}
.cp-small .picker {
    width: 100px;
    height: 100px;
}
.cp-small .slide {
    width: 15px;
    height: 100px;
}
.cp-small .slide-wrapper {
    margin-left: 5px;
}
.cp-small .picker-indicator {
    width: 1px;
    height: 1px;
    border: 1px solid black;
    background-color: white;
}
.cp-small .slide-indicator {
    width: 100%;
    height: 2px;
    left: 0px;
    background-color: black;
}

/* Fancy skin */

.cp-fancy {
    padding: 10px;
    /*    background-color: #C5F7EA; */
    background: -webkit-linear-gradient(top, #aaa 0%, #222 100%);
    float: left;
    border: 1px solid #999;
    box-shadow: inset 0 0 10px white;
}
.cp-fancy .picker {
    width: 200px;
    height: 200px;
}
.cp-fancy .slide {
    width: 30px;
    height: 200px;
}
.cp-fancy .slide-wrapper {
    margin-left: 10px;
}
.cp-fancy .picker-indicator {
    width: 24px;
    height: 24px;
    background-image: url(http://cdn1.iconfinder.com/data/icons/fugue/bonus/icons-24/target.png);
}
.cp-fancy .slide-indicator {
    width: 30px;
    height: 31px;
    left: 30px;
    background-image: url(http://cdn1.iconfinder.com/data/icons/bluecoral/Left.png);
}

/* Normal skin */

.cp-normal {
    padding: 10px;
    background-color: white;
    float: left;
    border: 4px solid #d6d6d6;
    box-shadow: inset 0 0 10px white;
}
.cp-normal .picker {
    width: 200px;
    height: 200px;
}
.cp-normal .slide {
    width: 30px;
    height: 200px;
}
.cp-normal .slide-wrapper {
    margin-left: 10px;
}
.cp-normal .picker-indicator {
    width: 5px;
    height: 5px;
    border: 1px solid gray;
    opacity: .5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    filter: alpha(opacity=50);
    background-color: white;
    pointer-events: none;
}
.cp-normal .slide-indicator {
    width: 100%;
    height: 10px;
    left: -4px;
    opacity: .6;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    filter: alpha(opacity=60);
    border: 4px solid gray;
    background-color: white;
    pointer-events: none;
}

#picker-wrapper {
    width: 200px;
    height: 200px;
    position: relative;
}
#slider-wrapper {
    width: 30px;
    height: 200px;
    position: relative;
}
#picker-indicator, #popup-picker-indicator {
    width: 3px;
    height: 3px;
    position: absolute;
    border: 1px solid white;
}
#slider-indicator, #popup-slider-indicator {
    width: 100%;
    height: 10px;
    position: absolute;
    border: 1px solid black;
}

/* CUSTOM STYLES */
.color-picker{
    margin: 0vw 1.4vw;

    //Make color picker tool look less bright so it's similar to CMYK
    .ui-colorpicker-map-layer-1, .ui-colorpicker-map-layer-2, .ui-colorpicker-bar {
        //filter: brightness(90%);
    }
    .ui-colorpicker-bar {
        //filter: brightness(90%);
        margin: 0 auto;
    }

    .value{
        display: inline-block;
        text-align: center;
        width: 44px;
        margin: 2px;
        input{
            text-align: center;
            width: 100%;
            padding: 5px;
            border: 1px solid rgba(128, 128, 128, 0.21);
            margin: .1em;
        }
        &.color{
            background-color: transparent;
            //border: solid 2px #cbcbcb;
            border-radius: 2px;
            margin-right: 0 !important;
        }
    }
    #picker-wrapper{
        width: 85%;
        height: 250px;
        display: inline-block;
        #picker, #popup-picker{
            height: 100%;
        }
    }
    #slider-wrapper{
        width: 10%;
        height: 250px;
        float: right;
        display: inline-block;
        #slider, #popup-slider{
            height: 100%;
        }
        #slider-indicator, #popup-slider-indicator{
          background-image: url('/assets/images/color-picker-slide.png');
            border: none;
            width: 44px;
            height: 16px;
            background-size: contain;
            background-repeat: no-repeat;
            left: 50%;
            margin-left: -22px;
        }
    }
    #picker-indicator, #popup-picker-indicator{
        width: 35px;
        height: 35px;
        border: none;
        background-position: center center;
        background-size: contain;
        background-image: url("/assets/images/color-picker-pointer.png");
    }
}

.apply-all-sides{
    text-align: center;
    text-decoration: underline;
    padding-top: 10px;
    cursor: pointer;
}

.color-swatches{
    p {
        margin-bottom: 2px !important;
    }
    .ng-scope{
        display: inline-block;
        span.swatch{
            height: 20px;
            width: 20px;
            border-radius: 50%;
            display: inline-block;
            cursor: pointer;
            margin: 2px;
            margin-right: 5px;
            box-shadow: 1px 1px 5px #888888;
        }
    }
}

.tool-icons{
    p {
        margin: 5px !important;
        cursor: pointer;
        float: right;
        img{

        }
    }
}
p.eyedropper-enable{
    cursor: pointer;
    img {
        float: right;
        width: 25px;
        height: 25px;
        margin-top: 10px;
    }
}

/*
COLOR SAMPLER
*/

#eyedropper-tail{
    display: none;
    position: fixed;
    //float: left;
    height: 50px;
    width: 50px;
    //background-color: red;
    border-radius: 50%;
    z-index: 9999;
    box-shadow: 1px 1px 20px #888888;
    border: 2px solid white;
}
.color-sampler-preview {
    z-index: 999;
    display: none !important;
}
/*!
 * [color-sampler]{@link https://github.com/emn178/color-sampler}
 *
 * @version 0.1.5
 * @author Chen, Yi-Cyuan [emn178@gmail.com]
 * @copyright Chen, Yi-Cyuan 2015-2016
 * @license MIT
 */.color-sampler-preview{position:absolute;z-index:100;display:none;width:110px;height:110px;border:1px solid;border-radius:50%;overflow:hidden;background-color:#fff;background-image:url(data:image/gif;base64,R0lGODlhDAAMAIABAMzMzP///yH5BAEAAAEALAAAAAAMAAwAAAIWhB+ph5ps3IMyQFBvzVRq3zmfGC5QAQA7);background-size:6px}.color-sampler-preview.active{display:block}.color-sampler-preview table{border-spacing:0}.color-sampler-preview td{border:1px solid gray;height:8px;width:8px;background-color:#BBB4B4;padding:0}.color-sampler-preview td[x="5"][y="5"]{border-color:red}.color-sampler-preview td[x="5"][y="4"]{border-bottom-color:red}.color-sampler-preview td[x="5"][y="6"]{border-top-color:red}.color-sampler-preview td[x="4"][y="5"]{border-right-color:red}.color-sampler-preview td[x="6"][y="5"]{border-left-color:red}


 /* NEW COLOR PICKER STYLING*/
.ui-widget.ui-widget-content {
  border: 0px solid #cccccc !important;
  background: transparent !important;
  margin-left: -10px;
}

.ui-colorpicker-hex-container{
  visibility: hidden;
}
.ui-colorpicker-cmyk{
  display: flex;
    margin-top: -18px;
  label {
    width: 100% !important;
    display: block !important;
    text-align: center;
  }
  input{
      border: 1px solid rgba(128, 128, 128, 0.21) !important;
      text-align: center;
      width: 100%;
      padding: 5px;
  }
  .ui-colorpicker-unit{
    display: none;
  }
  .ui-colorpicker-cmyk-c, .ui-colorpicker-cmyk-m, .ui-colorpicker-cmyk-y, .ui-colorpicker-cmyk-k, .value.color{
    flex: 1;
    width: 100%;
    margin: 0 10px;
  }
  .ui-colorpicker-cmyk-c{
      margin-left: 0;
  }
}


.ui-colorpicker,
.ui-dialog.ui-colorpicker {
    width:					auto;
    white-space:			nowrap;

    -webkit-touch-callout:	none;
    -webkit-user-select:	none;
    -khtml-user-select:		none;
    -ms-user-select:		none;
    user-select:			none;
}

.ui-colorpicker-inline {
    position:				inherit !important;
}

.ui-colorpicker-buttonset {
    float:					left;
    margin-left:			.4em;
}

.ui-colorpicker-buttonset .ui-button {
    margin:					.5em 0 .5em 0;
    cursor:					pointer;
}

.ui-colorpicker-buttonpane {
    background-image:		none;
    margin:					.7em 0 0 0;
    padding:				0 .2em;
    border-left:			0;
    border-right:			0;
    border-bottom:			0;
}

.ui-colorpicker-buttonpane button {
    float:					right;
    margin:					.5em .2em .4em;
    cursor:					pointer;
    padding:				.2em .6em .3em .6em;
    width:					auto;
    overflow:				visible;
}

.ui-colorpicker-buttonpane button.ui-colorpicker-current {
    float:					left;
}

.ui-colorpicker table {
    width:					100%;
    font-size:				100%; /* Reset browser table font-size */
    margin:					0;
}

.ui-colorpicker table td {
    vertical-align:			top;
}

.ui-colorpicker-padding-left {
    //padding-left:			10px;
}
.ui-colorpicker-padding-top {
    //padding-top:			10px;
}

.ui-colorpicker-border {
    //border:					1px inset;
    //display:				inline-block;
}

/* Bar & map */
.ui-colorpicker-map > *,
.ui-colorpicker-bar > * {
    position:				absolute;
    cursor:					crosshair;
}

.ui-colorpicker-map-pointer,
.ui-colorpicker-bar-pointer {
    position:				absolute;
    left:					0;
}

/* Map */
.ui-colorpicker-map,
.ui-colorpicker-map > * {
    display:				block;
    width:					256px;
    height:					256px;
    width:					285px;
    height:					284px;
    overflow:				hidden;
}

.ui-colorpicker-map-layer-1,
.ui-colorpicker-map-layer-2 {
    background:				url('../images/map.png') no-repeat;
    background:				url('../images/map-big.png') no-repeat;
}

.ui-colorpicker-map-128,
.ui-colorpicker-map-128 > * {
    width:					128px;
    height:					128px;
}

.ui-colorpicker-map-128 .ui-colorpicker-map-layer-1,
.ui-colorpicker-map-128 .ui-colorpicker-map-layer-2 {
    background:				url('../images/128/map.png') no-repeat;
}

.ui-colorpicker-map-layer-alpha {
    background:				url('../images/map-opacity.png');
}

.ui-colorpicker-map-pointer {
    display:				inline-block;
    width:					15px;
    height:					15px;
    background:				url('../images/map-pointer.png') no-repeat;
}

/* Bar */
.ui-colorpicker-bar,
.ui-colorpicker-bar > * {
    display:				block;
    width:					30px;
    height:					285px;
    overflow:				hidden;
    background-repeat:		repeat-x;
}

.ui-colorpicker-bar-128,
.ui-colorpicker-bar-128 > * {
    height:					128px;
}

.ui-colorpicker-bar-layer-1,
.ui-colorpicker-bar-layer-2,
.ui-colorpicker-bar-layer-3,
.ui-colorpicker-bar-layer-4 {
    background:				url('../images/bar.png') repeat-x;
    background:				url('../images/bar-big.png') repeat-x;
}

.ui-colorpicker-bar-128 .ui-colorpicker-bar-layer-1,
.ui-colorpicker-bar-128 .ui-colorpicker-bar-layer-2,
.ui-colorpicker-bar-128 .ui-colorpicker-bar-layer-3,
.ui-colorpicker-bar-128 .ui-colorpicker-bar-layer-4 {
    background:				url('../images/128/bar.png') repeat-x;
}

.ui-colorpicker-bar-layer-alpha {
    background:				url('../images/bar-opacity.png');
}

.ui-colorpicker-bar-layer-alphabar {
    background:				url('../images/bar-alpha.png');
}

.ui-colorpicker-bar-128 .ui-colorpicker-bar-layer-alphabar {
    background:				url('../images/128/bar-alpha.png');
}

.ui-colorpicker-bar, .ui-colorpicker-bar > * {
    overflow: visible !important;
}
.ui-colorpicker-bar-pointer {
    /*background-image: url('/images/bar-pointer.png');
    width: 20px;
    height: 7px;*/
    background-image: url('/assets/images/color-picker-slide.png');
    display: inline-block;
    width: 50px;
    height: 38px;
    background-position: center top;
    margin-top: -7px;
    margin-left: -11px;
    background-repeat: no-repeat;
}

/* Preview */
.ui-colorpicker-preview {
    text-align:				center;
    height:					20px;
}

.ui-colorpicker-preview-initial {
    cursor:					pointer;
}

.ui-colorpicker-preview-initial,
.ui-colorpicker-preview-current {
    width:					50px;
    height:					20px;
    display:				inline-block;
}

.ui-colorpicker-preview-initial-alpha,
.ui-colorpicker-preview-current-alpha {
    width:					50px;
    height:					20px;
    display:				inline-block;
    background:				url('../images/preview-opacity.png') repeat;
}


/* Inputs */
.ui-colorpicker-rgb label,
.ui-colorpicker-hsv label,
.ui-colorpicker-hsl label,
.ui-colorpicker-lab label,
.ui-colorpicker-cmyk label,
.ui-colorpicker-alpha label {
    width:					1.5em;
    display:				inline-block;
}

.ui-colorpicker-number {
    margin:					.1em;
    width:					4em;
}

/* Hex */
.ui-colorpicker-hex {
    text-align:				center;
}

/* Swatches */
.ui-colorpicker-swatches {
    height:					200px;
    overflow:				auto;
    background-color:		#f8f8f8;
}

.ui-colorpicker-swatch {
    cursor:					pointer;
    float:					left;
    width:					11px;
    height:					11px;
    border-right:			1px solid black;
    border-bottom:			1px solid black;
}

.ui-colorpicker-disabled {
    opacity:				.5;
    -ms-filter:				"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter:					alpha(opacity=50);
    pointer-events:			none;
}

.ui-colorpicker-disabled * {
    cursor:					default !important;
}

.ui-colorpicker-map-pointer {
    width: 34px;
    height: 34px;
    background-size: contain;
    margin-left: -9px;
    margin-top: -10px;
    background-position: center center;
}


/* RESPONSIVE STYLES */
@media (max-width: 1600px) {
    .white-popup {
        max-width: 275px !important;
    }
    .ui-colorpicker-cmyk .ui-colorpicker-cmyk-c, .ui-colorpicker-cmyk .ui-colorpicker-cmyk-m, .ui-colorpicker-cmyk .ui-colorpicker-cmyk-y, .ui-colorpicker-cmyk .ui-colorpicker-cmyk-k, .ui-colorpicker-cmyk .value.color {
        margin: 0 2px !important;
    }

    /* Map */
    .ui-colorpicker-map,
    .ui-colorpicker-map > * {
        display:				block;
        width:					200px;
        height:					200px;
        overflow:				hidden;
    }

    .ui-colorpicker-map-layer-1,
    .ui-colorpicker-map-layer-2 {
        background:				url('../images/map-small.png') no-repeat;
    }

    /* Bar */
    .ui-colorpicker-bar,
    .ui-colorpicker-bar > * {
        display:				block;
        width:					29px;
        height:					200px;
        overflow:				hidden;
        background-repeat:		repeat-x;
    }

    .ui-colorpicker-bar-layer-1,
    .ui-colorpicker-bar-layer-2,
    .ui-colorpicker-bar-layer-3,
    .ui-colorpicker-bar-layer-4 {
        background:				url('../images/bar-small.png') repeat-x;
    }
}

.tool-options{
    margin-top: 14px;
    margin-bottom: 10px;
    .box{
        padding: 0.43vw;
        border: 1px solid $g6;
        margin: 9px 0;
        text-align: left;
        font-size: 0.89vw;
        cursor: pointer;
        padding-left: 15px;
        transition: all 0.2s ease;
        img {
            padding-right: 6px;
            width: 1.82vw;
        }
        &:hover{
            color: $o1;
            border-color: $o1;
        }
        &.top-left{
            float: left;
            width: 45%;
        }
        &.top-right{
            float: right;
            width: 45%;
        }
        &.bottom{
            clear: both;
        }
    }
}

.sp-input-container.sp-cf{
    display: none;
}
.sp-button-container.sp-cf{
    display: none;
}

//Hide arrows/spinners on CMYK inputs
div.cmyk{
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

//Remove focus border outline
.open-popup-link{
    outline: none;
}

//Shapes border color picker style
.open-popup-link-shapes-border{
    display: flex;
    width: 20%;
    height: 2vw;
    background-color: red;
}