
$w1: #FFFFFF;
$b1: #000000;
$g1: #F9F9F9;
$g2: #F4F4F4;
$g3: #CBCBCB;
$g4: #333333;
$g5: #DBDBDB;
$g6: #BFBFBF;
$g7: #E8E8E8;
$g8: #5c5c5c;
$g9: #fafafa;
$g10: #999999;
$g11: #f2f2f2;
$g12: #CCCCCC;
$o1: #56b5f5;
$o2: #2a6696;
$r1: #ff665b;
$gr1: #2ecc71;


$bubbleColor: #A2AAAF; 

//Builder Header Colors
$textBlue: #56B5F5;
$textBlack: #212121;
$itemHoverBlue: #56B5F5;
$textSubMenu: #9fa3a7;

//About Us
$about-us-header-bg: #f7fafc;
$about-us-header-title: #004683;
$about-us-header-text: #808082;
$about-us-information-label: #34a4f5;

//Footer
$footer-bg: #E9EAEC;
$footer-color: #134573;

//Misc
$inactive_grey: #dadada;

@import "include-media.scss";

$breakpoints: (phone: 480px, tablet: 1024px, desktop: 1537px);


@import './cbb-font.scss';
@import "./tools.scss";

@import "./fonts.scss";
@import "./header.scss";
@import "./home.scss";
@import "./step.scss";
@import "./step1.scss";
@import "./step2.scss";
@import "./signin.scss";
@import "./color_picker.scss";
@import "./images.scss";
@import "./account.scss";
@import "./magnific-popup.scss";
@import "about-us";
@import "blog";
@import "bubbles.scss";
@import "popup.scss";
@import "animations.scss";
@import "boxes-cards.scss";
@import "processing-overlay.scss";
@import "progress-bar.scss";
@import "cbb-footer.scss"; 
@import "privacy-policy.scss"; 
@import "layout-helpers.scss"; 
@import "form.scss";
@import "sales-iq-chat.scss";

html,
body {
  overflow-x: hidden;  
}

body {
  overflow-y: scroll;
  font-family: Lato, sans-serif;
  font-weight: 300;
  margin: 0px;
  background: white;
  ::-webkit-scrollbar {
    width: 0px !important;
    background: transparent; /* make scrollbar transparent */
}
  * {
    box-sizing: border-box;
  }
  *.easing {
    transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  *.easing-1000 {
    transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  *.easing-250 {
    transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  a {
    color: $o1;
  }
  *.uppercase {
    text-transform: uppercase;
  }
  *.capitalize {
    text-transform: capitalize;
  }
  *.margin-right {
    margin-right: 5px;
  }
  *.bold {
    font-weight: 400;
  }
  input, textarea, button {
    outline: none;
    font-family: inherit;
    font-weight: 300;
  }
  *.fw-light {
    font-weight: normal;
  }
 
  *.fw-bold {
    font-weight: bold;
  }

  
}

.boxlayer { position: absolute !important; }
.box-border:hover {outline-style: dotted; outline-width: 0.2vw; outline-color: DodgerBlue; cursor: move;}
.box-border-h {outline-style: dotted; outline-width: 0.2vw; outline-color: #5f8cb1 ; cursor: nw-resize;}

.ui-resizable-handle
{
    background: #55b4f6;
    border: 1px solid #FFF;
    /*box-shadow: 0.5px 0.5px 5px;*/
    width: 9px !important;
    height: 9px !important;
    z-index: 2;
    border-radius: 5px;
}
.ui-resizable-se
{
    right: -5px !important;
    bottom: -5px !important;
    background-image: url("") !important;
}


.indesignbox { overflow: hidden !important; white-space: nowrap;}
.mm { top: -30px; position: absolute; }
.mi { padding-right: 10px; cursor: pointer; }
.mi:hover { opacity: 0.5; }

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 95%;
    overflow: auto;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 2vw;
}

.dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    height: 2vw;
}

.dropdown-content p:hover {background-color: #f1f1f1}

.show {display:block;}

.arial { font-family: Arial, Helvetica, sans-serif; }
.arialb { font-family: "Arial Black", Gadget, sans-serif; }
.comic { font-family: "Comic Sans MS", cursive, sans-serif; }
.courier { font-family: "Courier New", Courier, monospace; }
.georgia { font-family: Georgia, serif; }
.Impact { font-family: Impact, Charcoal, sans-serif; }
.lucidac { font-family: "Lucida Console", Monaco, monospace; }
.lucidas { font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif; }
.palatino { font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif; }
.tahoma { font-family: Tahoma, Geneva, sans-serif; }
.times { font-family: "Times New Roman", Times, serif; }
.trebuchet { font-family: "Trebuchet MS", Helvetica, sans-serif; }
.verdana { font-family: Verdana, Geneva, sans-serif; }
.gill { font-family: Gill Sans,Geneva,sans-serif; }

.RammettoOne { font-family: 'Rammetto One', cursive; }
.SupermercadoOne { font-family: 'Supermercado One', cursive; }
.Ubuntu{font-family: 'Ubuntu', sans-serif;}
.SourceSansPro{font-family: 'Source Sans Pro', sans-serif;}
.Dhurjati{font-family: 'Dhurjati', sans-serif;}
.Roboto{font-family: 'Roboto', sans-serif;}
.OpenSans{font-family: 'Open Sans', sans-serif;}
.Poppins{font-family: 'Poppins', sans-serif;}
.Anton{font-family: 'Anton', sans-serif;}

  .canvas-warper {
    display: none;
    z-index: 1000;
    position: fixed;
  }

.canvas-warper.visible {
  display: block;
}

.selectBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0.1vw solid #CBCBCB;
  border-radius: 0.2vw;
  height: 2vw;
  /*width: 100%;*/
  margin-right: 0;
  margin-left: 1.4vw;
  font-size: 0.8vw;
}

.largeSelect { 
  width: 58%;
}

.smallSelect { 
  width: 20%;
}

.layer-popup {
  z-index: 1000 !important;
}

.reset-popup {
    z-index: 1000 !important;
    p{
        text-align: center;
    }
}

.cpicker {
    background-color: white; 
    border: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
    position: relative;
    top: -1px;
}

.cpickerw {
  width: 0 !important;
  padding: 0;
  margin: 0 !important;
}

.cpickerb {
    background: '#ff3553';
    margin: 0.3vw;
    position: relative;
    border: none;
    width: 2.5vw;
    height: 1.4vw;
    border-radius: 1px;
}

.cpickerwb {
  width: 0 !important;
  padding: 0;
  margin: 0 !important;
}

.cpc {
  border: 0.1px solid silver;
  height: 2vw;
  border-radius: 2px;
}

.layer-box-design {
  width: 3vw;
  position: relative;
  cursor: pointer;
  display: flex;
  float: left;
  top: 50%;
  transform: translate(0%, -50%);
  left: 0.3vw;
  height: 80%;
}


.layer-box-design .layerIconBg {
  width: 100%;
  height: 100%;
}

.layer-box-design .layerIconImg {
  width: 50%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

span.layerIconImg{
    margin: 0 !important;
    width: 90% !important;
    border-radius: 2px;
    background-color: transparent;
}

.txt {
  font-size: 1.05vw;
  position: relative;
  display: inline-block;
  float: left;
  margin-left: 0.3vw;
  font-weight: 300;
  color: #424242;
  padding-left: 0.3vw;
  top: 50%;
  transform: translate(0%, -50%);
}

.del-layer {
  font-size: 1.3vw;
  position: relative;
  float: right;
  margin-right: 0.8vw;
  bottom: -0.6vw;
  background: white;
  border-radius: 50%;
  width: 1.25vw;
  height: 1.25vw;
  text-align: center;
  line-height: 1;
  color: #7a7a7a;}

.del-layer:hover {
  color: black; }

.box-txt {
  white-space: pre; }

.layers-box {
  background: #f8f8f8;
  width: 86%;
  height: 2.4vw;
  color: #888;
  border-radius: 5px;
  margin: 5px auto;
  display: block;
  box-shadow: none;
  &:hover{
    //border: 1px solid #aaa;
    box-shadow: inset 0 0 0 2px $o1, 0 0 1px transparent;
  }
}

#menuFabric.menuFabricImage .menuLButt {
  width: 24.9% !important;
}

.cropper-move {
  cursor: default !important;
}

.cropper-modal {
  background-color: #ffffff !important;
}

#image-container {
  height: 300px; /* use this as the maxHeight */
}

#menuFabric .menuLButt {
  background-color: white;
  cursor: pointer;
  background: linear-gradient(to bottom, #ffffff, #f9f9f9);
  width: 33%;
  height: 100%;
  border-left: 1px solid #c8cbcc;
  border-bottom: 1px solid #c8cbcc;
  border-top: 1px solid #c8cbcc;
  position: relative;
  display: inline-block;
  float: left;
  text-align: center;
  padding-top: 0.5vw; 
  font-size: 1.4vw;
  line-height: 2;
  }

#menuFabric .menuLButt img{
  width: 48%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 18%;
  display: block;
  
}

#menuFabric .bin img{
  width: 40%;
}


  #menuFabric label {
    position: absolute; 
    font-family: Tahoma; 
    font-size: 0.8vw !important;
    font-weight: normal !important;
    display: block;
    left: 0;
    right: 0;
    bottom: -5%; 
    cursor:pointer;
  }

#menuFabric.menuFabricImage {
  width: 16vw !important;
}

#menuFabric {
  background-color: lightgrey;
  display: none;
  width: 12vw;
  height: 4vw; }

.drop-down-fs{
   max-height: 100px; /*Provide height in pixels or in other units as per your layout*/
   overflow-y: auto; /*Provide an auto overflow to diaply scroll*/
  }



.ui-rotatable-handle_ {
  height: 16px;
  width: 16px;
  cursor: pointer;
  background-color: red;
  background-size: 100%;
  left: 80px;
  top: -60px;
}

#WebGL-output canvas:not(.rotating) {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#WebGL-output canvas.rotating {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}


#WebGL-output canvas {
  /*border: 10px solid orange;*/
    margin-top: -245px;
    margin-left: -45px !important;
 
    @include respond-to (1600px){
        margin-top: -190px;
        margin-left: 0 !important;
    }
    @include respond-to (1000px){
        margin-top: -150px;
        margin-left: 0 !important;
    }
/*    @include respond-to (800px){
        margin-top: -150px;
        margin-left: -200px !important;
    }
    @include respond-to (600px){
        margin-top: -150px;
        margin-left: -250px !important;
    }*/
}
#fcanvas{
    cursor: url("/assets/images/drop.png"), auto !important;
}


.layer-icon-circ{
    width: 1vw;
    height: 1vw;
    background: #bbb;
    -moz-border-radius: 0.5vw; 
    -webkit-border-radius: 0.5vw; 
    border-radius: 0.5vw;
    /*border: 1px solid rgba(0, 0, 0, 0.4);*/
 
}

.layer-icon-tri{
    width: 0; 
    height: 0; 
    border-bottom: 1.2vw solid #bbb; 
    border-left: 0.6vw solid transparent; 
    border-right: 0.6vw solid transparent; 
}

.layer-icon-rect{
    width: 1vw; 
    height: 1vw; 
    background: #bbb;
}

.layer-icon-star {
    margin-top: 0.5vw;
    color: #bbb;
    width: 0;
    height: 0;
    border-right:  1vw solid transparent;
    border-bottom: 0.7vw  solid #bbb;
    border-left:   1vw solid transparent;
    -moz-transform:    rotate(35deg);
    -webkit-transform: rotate(35deg);
    -ms-transform:     rotate(35deg);
    -o-transform:      rotate(35deg);
}

.layer-icon-star:before {
   border-bottom: 0.8vw solid #bbb;
   border-left: 0.3vw solid transparent;
   border-right: 0.3vw solid transparent;
   position: absolute;
   height: 0;
   width: 0;
   top: -0.45vw;
   left: -0.65vw;
   display: block;
   content: '';
   -webkit-transform: rotate(-35deg);
   -moz-transform:    rotate(-35deg);
   -ms-transform:     rotate(-35deg);
   -o-transform:      rotate(-35deg);

}
.layer-icon-star:after {
   position: absolute;
   display: block;
   color: red;
   top: 0.03vw;
   left: -1.05vw;
   width: 0;
   height: 0;
   border-right: 1vw solid transparent;
   border-bottom: 0.7vw solid #bbb;
   border-left: 1vw solid transparent;
   -webkit-transform: rotate(-70deg);
   -moz-transform:    rotate(-70deg);
   -ms-transform:     rotate(-70deg);
   -o-transform:      rotate(-70deg);
   content: '';
}

.star-container{
      -ms-transform: scale(0.7);
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
}

.layer-shape{
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0.1vw;
}

.image-icon-layer{

    max-width: 2.5vw;
    max-height: 80%;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0.1vw;
}

#reamaze-widget {
  bottom: 2.55vw !important;
  right: 3.19vw !important;
}