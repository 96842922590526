div.step1 {
  div.box-selection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 1vw;

    div.box-selection-option {
      margin: 1vw;
      border-radius: 1.5vw;
      border: 0.1vw solid $o2;
      box-shadow: 5px 5px 15px $g3;
      width: 12vw;
      height: 12vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        display: block;
        height: 50%;
        margin: 0px auto;
        &.panel {
          height: 40%;
        }
      }
      p {
        font-size: 1.5vw;
        margin: 0px 0px 1vw 0px;
      }
      &.active {
        border: 0.25vw solid $o2;
      }
    }
  }

  div.active-box {
    padding-top: 12.75vw;
  }

  div.description {
    padding: 2.5vw 1vw 1vw 2vw;
    div.description-container {
      padding-top: 2.5vw;

      ul {
        li {
          font-size: 1.25vw;
          margin-bottom: 2.25vw;
          text-align: justify;
          text-justify: inter-word;
          &:last-of-type {
            margin-bottom: 0vw;
          }
          cursor: default;
        }
      }
    }
  }

}
