//Responsive Mixin
@mixin respond-to($query) {
    @media (max-width: $query) {
        @content
    }
}

//From Pixels to Ems
@function em($target, $context: 16) {
  @return ($target / $context) * 1em;
}
  
//Loader settings and mixings
$loader-color:              $o1 !default;
$loader-size:                  56px !default;
$loader-height:                20px !default;
$loader-border-size:            8px !default;
$loader-gap:                   12px !default;
$loader-animation-duration:      1s !default;

@mixin loader-rotate {
    @keyframes loader-rotate {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

@mixin loader-scale {
    @keyframes loader-scale {
        0% {
            transform: scale(0);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
}
@function circle-angle($size) {
    @return round($size * 0.70710678118) + ($size / 2);
}

@function circle-normal($size) {
    @return round($size * 0.70710678118) + $size;
}

@mixin loader07(
    $size: $loader-size,
    $color: $loader-color,
    $duration: $loader-animation-duration,
    $align: null
) {
    $unique-name: unique-id();
    $dot-color: rgba($color, 0.05), rgba($color, 0.1), rgba($color, 0.2), rgba($color, 0.3), rgba($color, 0.4), rgba($color, 0.6), rgba($color, 0.8), rgba($color, 1);
    width: $size;
    height: $size;
    border-radius: 50%;
    position: relative;
    animation: #{'loader07-'}#{$unique-name} $duration linear infinite;
    @if ($align == center) {
        margin: 0 auto;
    }
    @if ($align == middle) {
        top: 50%;
        margin: -$size / 2 auto 0;
    }
    @keyframes #{'loader07-'}#{$unique-name} {
        0% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 1),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 2),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 3),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 4),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 5),
                        // Bottom Left
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 6),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 7),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 8);
        }

        12.5% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 8),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 1),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 2),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 3),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 4),
                        // Bottom Left
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 5),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 6),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 7);
        }

        25% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 7),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 8),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 1),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 2),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 3),
                        // Bottom Left
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 4),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 5),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 6);
        }

        37.5% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 6),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 7),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 8),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 1),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 2),
                        // Bottom Left
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 3),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 4),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 5);
        }

        50% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 5),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 6),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 7),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 8),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 1),
                        // Bottom Left
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 2),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 3),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 4);
        }

        62.5% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 4),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 5),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 6),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 7),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 8),
                        // Bottom Left
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 1),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 2),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 3);
        }

        75% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 3),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 4),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 5),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 6),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 7),
                        // Bottom Lefts
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 8),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 1),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 2);
        }

        87.5% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 2),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 3),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 4),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 5),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 6),
                        // Bottom Left
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 7),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 8),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 1);
        }

        100% {
            box-shadow: // Top
                    0 (- circle-normal($size)) 0 0 nth($dot-color, 1),
                        // Top Right
                    circle-angle($size) (- circle-angle($size)) 0 0 nth($dot-color, 2),
                        // Right
                    circle-normal($size) 0 0 0 nth($dot-color, 3),
                        // Bottom right
                    circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 4),
                        // Bottom
                    0 circle-normal($size) 0 0 nth($dot-color, 5),
                        // Bottom Left
                            (- circle-angle($size)) circle-angle($size) 0 0 nth($dot-color, 6),
                        // Left
                            (- circle-normal($size)) 0 0 0 nth($dot-color, 7),
                        // Top left
                            (- circle-angle($size)) (- circle-angle($size)) 0 0 nth($dot-color, 8);
        }
    }
}