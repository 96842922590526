.bubble {
    position: absolute;
    bottom: 0.65vw;
    //left
    //left: 0;
    max-width: 16.625vw;
    left: calc(50% - 8.2vw);
    width: 100%;
    height: 4vw;
    padding: 0.7vw;
    box-sizing: border-box;
    background: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 15px;
    border: $bubbleColor solid 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: lighten($g8, 10%); 
    font-size: 0.73vw;
    text-align: justify;
    display: none;
    //Modifiers
    &.is-left {
        &:after,
        &:before {
            left: 12%;
        } 
    }
    &.is-right {
        &:after,
        &:before {
            left: initial;
            right: 12%;
        }
    }
    &.is-active {
        display: flex;
    }
}

.bubble:after {
    content: '';
    position: absolute;
    border: 1px solid $bubbleColor;
    border-bottom: none;
    border-right: none;
    background: $w1;
    display: block;
    z-index: 1;
    left: calc(50% - 10px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    z-index: 1;
    top: -8px;
}
