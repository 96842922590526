/*Loading a google font*/
@import url(https://fonts.googleapis.com/css?family=Lato:700,600,500,400,300,200,100);
/*@import url("https://fonts.googleapis.com/css?family=OpenSans|Anton|Dhurjati|Open+Sans|Poppins|Rammetto+One|Roboto|Source+Sans+Pro|Supermercado+One|Ubuntu");*/

/* Standardise the font-family names, add appropriate values for
weights and styles */

/* In variables partial */

$font-family-1: 'amble', sans-serif;
$font-bold: 700;

/* In type partial */
@font-face {
    font-family: 'amble';
    src: url('http://karenmenezes.com/font-amble/Amble-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'amble';
    src: url('http://karenmenezes.com/font-amble/Amble-Italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'amble';
    src: url('http://karenmenezes.com/font-amble/Amble-Bold-webfont.woff') format('woff');
    font-weight: $font-bold;
    font-style: normal;
}

/* Then use elsewhere */

body {
    //font-family: $font-family-1;
}

/*@font-face
{
    font-family: 'Supermercado One';
    src: url('../assets/fonts/SupermercadoOne-Regular.ttf') format('truetype');
}

@font-face
{
    font-family: 'RammettoOne';
    src: url('../assets/fonts/RammettoOne-Regular.ttf') format('truetype');
}

@font-face
{
    font-family: 'Ubuntu';
    src: url('../assets/fonts/Ubuntu-Regular.ttf') format('truetype');
}

@font-face
{
    font-family: 'SourceSansPro';
    src: url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face
{
    font-family: 'Dhurjati';
    src: url('../assets/fonts/Dhurjati-Regular.ttf') format('truetype');
}

@font-face
{
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face
{
    font-family: 'OpenSans';
    src: url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face
{
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face
{
    font-family: 'Anton';
    src: url('../assets/fonts/Anton-Regular.ttf') format('truetype');
}*/